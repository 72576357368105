.search-result div[data-role="response"] h2{
	padding: 1rem 0;
    margin: 0;
    font-size: 2.4rem;
}

.search-result #wrapper div.container {
	margin-top:2rem;margin-bottom: 3rem;
}
	
.do-invite {
line-height: 2!important;
cursor: pointer!important;
}
#bg-loading{
	background-color:black;
	height: 100%;
	opacity: .8;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9998;
	display:none;
}

#loading {
	position:fixed;
	top: calc(50% - 140px);
	width: 100%;
	max-width: 562px;
	max-height: 270px;
	height: 100%;
	background-color: black;
	text-align: center;
	padding: 40px;
	z-index: 9999;
	display: none;
}

#loading .brand-logo,
#loading .load{
	display:block;
	text-align: center;
	clear:both;
}

#loading img {
	max-height: 75px;
	max-width: 200px;
}

#loading .brand-logo{
	padding-bottom: 20px;
}


#loading .load{
	color: white;
	text-transform: uppercase;
	font-size: 18px;
	border-top: 1px solid #6f7c85;
	border-bottom: 1px solid #6f7c85;
	padding: 15px;
}

@media (max-width: 767px){
	#loading {
	    left: 0!important;
	    right: 0;
	}


}

@media only screen (max-width: 1200){ 
	.container>.navbar-header {
		min-width: calc(100% - 10px);
	}

	#header .collapse {
		padding:0;
	}

	#header>.container {
		padding:0!important;
		width:100%;
	}

	.navbar-header img:not(#hamburguer) {
		padding: 0;
	}

	#header button {
		background-color: transparent;
		border: none;
		margin: 0 .5rem;
		position: absolute;
		right: 0;
		top: 15%;
	}

	#header button #hamburguer {
		margin-right: 10px;
	}
}

@media(max-width: 769px){
	#loading {
	    top: 10%;
	    width: 100%;
	    padding: 20px;
	    height: initial;
	}
	
	#loading .brand-logo img{
		width: 80%;
		max-width: 200px;
	}
}

@media(min-width: 768px){
	#loading {
		left: calc(50% - 281px);
	}
}

.skiptranslate.goog-te-gadget {
	background-color: whitesmoke;
    padding: 5px 15px;
    text-align: right;
    font-family: inherit;
}
.skiptranslate.goog-te-gadget > div {
	display: inline-block!important;
	margin-right: 5px!important;
}

.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    font-family: unset;
}

@media (max-width: 992px){
	.skiptranslate.goog-te-gadget { 
		text-align: center;
	}
	.skiptranslate.goog-te-gadget > div { display:block!important;}
	
	.goog-te-gadget .goog-te-combo {
	    border: 1px solid gray!important;
	    padding: 5px 10px!important;
	    margin-bottom: 10px!important;
	}
}

#wlabels {
	text-align-last: center;
    padding: 10px;
    width: 100%;
    text-align: center;
    border: 1px solid #a4acb3!important;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #6d7984!important;
}

#search-resorts-list h2 {
    padding:0;
    background-image: url('images/vow.png');
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: 0;
    font-size: 2.3rem;
    padding: 5px 0 5px 30px;
}

#search-resorts-list h4{
	padding: 0;
    margin-top: 0;
    font-size: 1.5rem;
}

#search-resorts-list .modal-wrapper {
	padding: 2rem!important;
	background-color:#f9f7f7!important;
}

#search-resorts-list #list{
	height: 435px;
	overflow-y: scroll;
	padding-left: 20px;
	padding-right: 20px;
}

#search-resorts-list .details-box {
    padding: 15px!important;
    margin-right: 5px;
    width: calc(33% - 4px);
    margin-bottom: 5px;
	min-height: 140px;
	color: #6d7984;
}
#search-resorts-list .rate{
    font-size: 1.2rem;
    color:#acb3b9;
}

#search-resorts-list .face-1,
#search-resorts-list .face-2,
#search-resorts-list .face-3,
#search-resorts-list .face-4,
#search-resorts-list .face-5{
	color:#acb3b9!important;
}
#search-resorts-list .title {
	font-size: 1.8rem;
	line-height: 1.2;
    color: #76828c;
    text-transform: capitalize;
	margin-top: 3px;
    margin-bottom: 10px;
}

#search-resorts-list .checkbox{
	margin-top: 0;
    display: inline-block;
    font-size: 1.2rem;
}

#search-resorts-list .checkbox .cr{
	color: #fcc669;
    font-size: 1.5rem;
    padding: 1.1rem;
}
#search-resorts-list .checkbox label{
	padding-left: 0!important;
}

#search-resorts-list .checkbox label .name{
	display: inline-block!important;
    width: calc(100% - 35px)!important;
    line-height: 1.4rem;
    font-weight: 600;
}

#search-resorts-list .small-info {
	position: absolute;
    bottom: 15px;
    right: 15px;
}
#btn-rlist {
	background-image: url('images/vow_white.png');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 10px 10px;
	border-radius: 0.2rem!important;
    cursor: pointer!important;
    color: white;
    padding: 1rem;
    margin-right: 1rem;
    display: block;
    margin-left: 1rem;
    text-align: center;
    background-color: #88919a;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
    top: 10px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a4acb3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
	height: 25px;
	width: 25px;
    left: -2px;
   	bottom: 0;
	top: -2px;
  background-color: white;
  border:2px solid #7d8892;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ea732d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ea732d;
}

input:checked + .slider:before{
	border-color: #f6a270;
	left: 12px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.auto-populate {
	font-family: 'Poppins', Verdana, sans-serif;	
	font-size: 16px;
    margin-bottom: 20px;
    color: #7d8892;
}

.auto-populate .custom-tooltip {
	cursor: pointer;
    position: relative;
    display: inline-block;
    color: #ea732d;
    background-image:url('images/admin_tooltip_info.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 1.2rem;
    vertical-align: initial;
    height: 1.2rem;
}

.auto-populate .custom-tooltip .tooltiptext {
	visibility: hidden;
	width: 18rem;
	background-color: white;
	color: #ea732d;
	text-align: center;
	border-radius: 6px;
	padding: 0.5rem;
	position: absolute;
	z-index: 1;
	top: 50%;
	font-size:1.2rem;
	right: 0;
	margin-right: 0;
	margin-top: 2rem;
	opacity: 0;
	transition: opacity 1s;
	border-radius:2px;
	border:1px solid rgba(215,215,215,0.8);
	box-shadow:1px 1px 1px rgba(215,215,215,0.8);
}
.auto-populate .custom-tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.auto-populate .tooltiptext strong{
	color:inherit;
}

