.flat-windows .modal-content{
	border-radius: 0px!important;
}
.modal-dialog.big{
	width: 75%;
}

[id^="details-"]{
	color:#76828c;
}

[id^="details-"] .item img {
    width:100%;
}
[id^="details-"] table td.desc{
	line-height: inherit;
    padding: 1rem;
    text-align: center;
}

[id^="details-"] h3 {
	background-repeat: no-repeat;
	padding-left: 6.5rem;
    background-size: auto calc(100% - 0.1rem);
    background-position: 1rem center;
    vertical-align: middle;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

[id^="details-"] input {
	width: inherit;
}

[id^="details-"] td .discount{
	color:#ea732d;
	font-weight: 600;
	font-size: 1.4rem;
}

[id^="details-"] .details-box td a,
[id^="details-"] .details-box td a:active{
    background: none!important;
    color: inherit!important;
    padding: 0!important;
    width: auto!important;
    font-size: 1.2rem!important;
    display: block;
    font-weight: normal;
    text-decoration: underline;

}

[id^="details-"] .details-box td {
    vertical-align: middle;

}

.modal#invitationsDetails table,
[id^="details-"] table{
	margin-top: 20px;
}



.modal#invitationsDetails table th,
.modal#invitationsDetails table td,
[id^="details-"] table th,
[id^="details-"] table td{
	text-align: center;
}

.modal#invitationsDetails table tbody tr,
[id^="details-"] table tbody tr{
	background-color: transparent!important;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-size: 1.3rem;
    font-weight: 600;
    color: #465664;
}
.modal#invitationsDetails table tbody tr:not(:last-child),
[id^="details-"] table tbody tr:not(:last-child){
    border-bottom: 10px solid #e8e8e8;
}
.modal#invitationsDetails table tbody tr:last-child,
[id^="details-"] table tbody tr:last-child{
	border-bottom: 1px solid #e8e8e8;
}
.modal#invitationsDetails#invitationsDetails table thead,
[id^="details-"] table thead{
    background-color: #c4c9cd;
    border-bottom: 1px solid white;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
}

.modal#invitationsDetails#invitationsDetails  table th,
[id^="details-"] table th{
	vertical-align: middle!important;
}

[id^="details-"] table td {
	line-height: 4;
}

.modal#invitationsDetails#invitationsDetails table td img.email,
[id^="details-"] table td img.email{
	content: url('../images/modals/email.png');
}

.modal#invitationsDetails#invitationsDetails table td img.fb,
[id^="details-"] table td img.fb{
	content: url('../images/modals/email.png');
}
.modal#invitationsDetails table tbody tr [data-role="change"]:after:first-child,
[id^="details-"] table tbody tr [data-role="change"]:after:first-child{
	border-right: 1px solid #465664;
}
.modal#invitationsDetails table tbody tr [data-role="change"],
[id^="details-"] table tbody tr [data-role="change"]{
	font-weight: 400;
    font-family: "Poppins";
    font-size: 1.3rem;
}

    /* Let's get this party started */
.modal-wrapper ::-webkit-scrollbar {
    width: 15px;
}

/* Track */
.modal-wrapper ::-webkit-scrollbar-track {
    background: #d4d8db;
    -webkit-box-shadow: inset 0 0 1px #465664;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid white;
        cursor: pointer;

}

/* Handle */
.modal-wrapper ::-webkit-scrollbar-thumb {
    cursor: pointer;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-image: url(http://www.urban-commons.com/images/buttons/arrow_up.png);
    background-size: 8px;
    background-position: center center;
    background: #465664;
/*     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}
.modal-wrapper ::-webkit-scrollbar-thumb:window-inactive {
    background: #465664;
        cursor: pointer;

}
