/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
i.region{	background-color: #a4ce3a!important;color:white!important;}
i.city{	background-color: #ea732d!important;color:white!important;}
i.area{	background-color: #529ad3!important;color:white!important;}
i.resort{background-color: #d04d98!important; color:white!important;}
i.all{background-color: #2a65b7!important; color:white!important;}
i.area,i.city,i.region,i.resort,i.all{ border-radius: 2px; padding-left: 4px!important; padding-right: 4px!important;text-transform: uppercase!important;}
.iconds{color:#d04d98!important;}
.edit-wl{color:inherit!important;}
.search-wl{color:white; background-color:#465664; border-radius: 3px; margin-left: 8px;}
.wl-input-search{margin-left: 30px;}
.wl_date{width:46%!important; float:left; margin-left: 2%; margin-right: 2%;}
.to_wl{width: 4%!important;float:left;}
.wl_intdate{width:100%!important;float:left;}
.lawo{width: 47%!important;float:left;}
.lawo2{width: 47%!important;float:left; margin-left: 6%;}
.notify{margin-left: 5px;font-size: 15px!important;margin-top:2px; z-index: 100;}
.note{display:none!important;}
.navbar-right .dropdown-menu, .dropdown.open,.dropdown.open > a.dropdown-toggle{
	background-color: #929ba3 !important;
}
