#carousel-slider, #carousel-slider .item {
	height: 400px;
	background-position: center center;
	background-size: cover;
	margin-bottom: 1rem;
}
.carousel-inner > .item > img, 
.carousel-inner > .item > a > img{
	width: 100%; /* use this, or not */
	margin: auto;
}

.carousel-inner>.item>img, .carousel-inner>.item>a>img {
	margin: 0 auto;
}

.glyphicon-right,
.glyphicon-left{
	background-repeat: no-repeat;
    height: 7rem;
    width: 4rem;
    top: calc(50% - 3.5rem);
}
.glyphicon-right{
	background-image:url("../images/slider-right.png");
}

.glyphicon-left{
	background-image:url("../images/slider-left.png");
}