@charset "UTF-8";

/*@import "../common.css";
@import "common/slider.css";
@import "common/modals.css";*/


/*
div.loading-mg {
    display: flex;
    justify-content: center;
}

div.limage {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
*/

@font-face {
    font-family: "Poppins";
    src: url(fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Light";
    src: url(fonts/Poppins-Light.ttf) format('truetype');
}

.defs-only {
    display: none;
}

#loading {
    background-color: transparent;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Poppins', Verdana, sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    position: relative;
    color: #04416D;
}

a {
    color: inherit;
}

a:link,
a:hover,
a:visited,
a:active {
    cursor: pointer;
}

a[data-role="change"]:active {
    background-color: inherit!important;
    display: inherit!important;
}

#header {
    background-color: white ;
    /*#04416D;*/
    color: #04416D;
    /* white;*/
    border-bottom: 1px solid #5f6d79;
}

footer {
    background-color: #04416D;
    color: white;
}

#header {
    /*min-height: 100px;*/
}

#header a,
footer a,
#header a:visited,
footer a:visited #header a:link,
footer a:link,
#header a:active,
footer a:active,
#header a:hover,
footer a:hover {
    /*color:white;*/
    cursor: pointer;
    padding: 0 7px;
}

#header>.container {
    position: relative;
    padding: 0;
}

#hold-info {
    background-color: #e27132;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 1rem;
    position: relative;
    left: -10px;
    top: -5px;
}

.navbar-header {
    /*padding: 3rem 0 2rem 0;
    max-width: 28rem;
    width: 100%;*/
}

.navbar-header a>img {
  /*max-height: min-content;*/
  height: 4.5rem;
  /*width: min-content;
  max-width: 255px;
  display: inline;*/
  margin: 1rem 2rem;
}

.navbar-right .dropdown-menu,
.dropdown.open {
    border-radius: 0px;
    background-color: #929ba3;
}

.dropdown-menu>li>a {
    padding: 1rem;
    display: block;
}

.dropdown-menu>li:not(:first-child) a {
    border-top: 0.1rem solid #747f8a;
}

#header button {
    background-color: transparent;
    border: none;
    /*margin: 0 0.5rem;*/
}

#header button #hamburguer {
  /*margin-top: 0;
  padding: 0;
  margin-bottom: 20px;
  position: absolute;
  right: 0;*/
}

#header #signin button,
#header #signup button {
    margin: 0 1rem;
}

#header #signup,
#header #itinerary {
    border-left: 1px solid #5f6d79;
}

#header #itinerary {
    font-size: 1.3rem;
}

#header #itinerary span {
    color: #e27132;
}

@media(min-width: 1200px) {
  #header #itinerary span {
      padding-left:1rem;
  }
  #itinerary a.checkout{
    background-color: none !important;
  }
}

#header #itinerary a {
    /*padding: 0;
    color: white;*/
    font-size: 1.4rem;
    font-weight: 600;
}

#header #itinerary a:not(:first-child):after {
    content: "\003E"
}

li#wallet {
    /*line-height: 11.5rem;
    margin-left: 1rem;*/
}

.navbar-nav>li:not(#itinerary) {
    /* 	top: 4rem; */
    vertical-align: middle;
}

.navbar-nav>li {
    line-height: 11.5rem;
}

#header #currencies span,
#header #languages span,
#header #signin span,
#header #signup span,
#header #messages span.icon,
#header #wallet span.icon,
#header #logout span.icon,
#header #user span {
    display: inline-block;
    border-radius: 5rem;
    line-height: 2rem;
    width: 2.9rem;
    text-align: center;
    padding: 0.5rem 0px;
    height: 2.9rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    vertical-align: middle;
}

#header #currencies span,
#header #languages span,
#header #wallet span,
#header #user span{
  margin-left: 1rem;
  margin-right: 1rem;
}

#header #currencies span {
    color: white;
    background-color: #04416D;
}

#header #currencies span,
#header #languages span {
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    font-weight: 600;
}

#header #currencies ul {
    text-align: right;
}

#header #languages span.en {
    background-image: url('images/languages/en.png');
}

#header #languages span.es {
    background-image: url('images/languages/es.png');
}

#header #languages span.no {
    background-image: url('images/languages/no.png');
}

#header #languages span.de {
    background-image: url('images/languages/de.png');
}

#header #languages span.fr {
    background-image: url('images/languages/fr.png');
}

#header #languages span.it {
    background-image: url('images/languages/it.png');
}

#header #languages span.chi {
    background-image: url('images/languages/chi.png');
}

#header #languages span.pt {
    background-image: url('images/languages/pt.png');
}

#header #languages span.zh {
    background-image: url('images/languages/zh.png');
}

#header #languages span.nl {
    background-image: url('images/languages/nl.png');
}

#header #languages span.de {
    background-image: url('images/languages/de.png');
}

#header #languages span.sv {
    background-image: url('images/languages/sv.png');
}

#header #languages span.ru {
    background-image: url('images/languages/ru.png');
}

#header #languages span.ja {
    background-image: url('images/languages/ja.png');
}

#header #languages span.da {
    background-image: url('images/languages/da.png');
}

#header #languages span.fi {
    background-image: url('images/languages/fi.png');
}

#header #languages span.is {
    background-image: url('images/languages/is.png');
}

#header #languages span.it {
    background-image: url('images/languages/it.png');
}

#header #languages span.pl {
    background-image: url('images/languages/pl.png');
}

#header #languages span.cs {
    background-image: url('images/languages/cs.png');
}

#header #languages span.sk {
    background-image: url('images/languages/sk.png');
}

#header #languages span.hr {
    background-image: url('images/languages/hr.png');
}

#header #languages span.el {
    background-image: url('images/languages/el.png');
}

#header #languages span.ro {
    background-image: url('images/languages/ro.png');
}

#header #languages span.bg {
    background-image: url('images/languages/bg.png');
}

#header #languages span.hi {
    background-image: url('images/languages/hi.png');
}

#header #languages span.vi {
    background-image: url('images/languages/vi.png');
}

#header #languages span.th {
    background-image: url('images/languages/th.png');
}

#header #messages span.icon,
#header #wallet span.icon,
#header #logout span.icon {
    border-radius: 0;
    background-size: inherit;
    background-position: center center;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 4.5rem!important;
}

#header #messages span.icon {
    background-image: url('images/messages-icon.png');
}

#header #logout span.icon {
    margin: 0;
    width: 3.5rem!important;
    background-image: url('images/logout-icon_blue.png');
}

#header #messages span#notify {
    background-color: #ea732d;
    color: white;
    line-height: normal;
    border-radius: 20rem;
    width: 2rem;
    position: absolute;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: -10px;
    right: 2rem;
    top: 4rem;
}

#header #wallet span.icon {
    background-image: url(images/wallet-icon_blue.png);
    /*margin-right: 0rem!important;*/
}

#header #wallet span.available {
    color: #e27132;
    margin-right: 1rem;
}

#header #signin span,
#header #user span {
    margin-right: 0.5rem;
    background-image: url('images/user-icon_blue.png')
}

#header #user img {
    margin-right: 0.5rem;
}

#header #signup span {
    margin-right: 0.5rem;
    background-image: url('images/check-icon_blue.png')
}

#header #currencies span.usd:before {
    content: "\0024";
}
#header #currencies span.brl:before {
    content: "R\0024";
}
#header #currencies span.eur:before {
    content: "\20AC";
}

#header #currencies span.gbp:before {
    content: "\00A3";
}

#header #currencies span.mxn:before {
    content: "MXN";
    font-size: 0.8em;
    padding: 0;
}

#header #currencies span.sgd:before {
    content: "S\0024";
}

#header #currencies span.cny:before {
    content: "\00A5";
}

#header #currencies span.cad:before {
    content: "C\0024";
}

#header #currencies ul {
    min-width: 19rem;
    width: 100%;
}

#header #languages ul,
#header #currencies ul,
#header #messages ul,
#header #user ul {
    margin: 0;
    border: 0;
    padding: 0;
}

#header #user img {
    max-width: 2.9rem;
    border-radius: 10rem;
    border: 1px solid white;
}

#wrapper {
    background-color: white;
}

h1,
h2,
h3,
h4 {
    font-family: 'Poppins', Verdana, sans-serif;
    font-weight: 600;
    font-size: 2.8rem;
    padding: 0 0 2rem 0;
}

#wrapper #search h2 {
    font-size: 3.4rem;
    margin: 0;
    padding: 0.5rem 0;
    position: relative;
}

#wrapper #search {
    padding: 11.1rem 0;
    color: white;
    background-image: url('images/search_main.jpg');
    background-size: cover;
    background-position: center bottom;
}

#wrapper .destinations {
    margin-bottom: 10.1rem;
    font-size: 2rem;
    margin-top: 5rem;
}

#wrapper .destinations .img-responsive {
    width: calc(100% - 1rem);
}

#wrapper .destinations small {
    color: #838e97;
}

#wrapper .destinations h2,
#wrapper #what-is h2 {
    font-size: 3rem;
}

#wrapper #what-is {
    padding: 5rem 0;
    background-color: #2AABE4;
    display: block;
    /*margin: 2.5rem 0;*/
    color: white;
    font-size: 2rem;
}

#wrapper #what-is img {
    display: inline;
    margin-bottom: 2rem;
}

#wrapper #we-believe {
    padding: 5rem 0;
    background-color: #2b5485;
    color: white;
    background-image: url(images/city-cartoon.jpg);
    background-size: cover;
    background-position: center bottom;
    font-size: 2rem;
    min-height: 43rem;
}

footer {
    padding: 4.2rem 0 7rem 0;
    text-align: center;
    font-size: 1.1rem;
}

footer .copy {
    font-size: .5rem;
}

footer div.left,
footer div.right {
    display: inline-block;
}

footer div.left {
    text-align: left;
}

footer div.right {
    text-align: right;
}

footer ul {
    /* display: inline-block; */
}

footer ul li {

    text-align: left;
    margin-left: 1.5rem;
    vertical-align: middle;
    line-height: 2.5rem;
}


footer ul li.follow-us {
    margin-left: 2rem;
    width: 40%;
}

.chat-wu{
  background-image: url('images/Chat.svg');
  width: 7.5rem;
  height: 7.5rem;
  display: inline-block;
  background-size: cover;
  position: absolute;
  bottom: 0%;
  left: 130%;
}

footer ul li:not(.follow-us):before {
    content: " \B7  ";
}

footer ul li:last-child) {
    margin-left: 3.8rem;
}

footer .follow-us a {
    margin-left: 3%;
}

footer .follow-us .fb,
footer .follow-us .twitter,
footer .follow-us .instagram,
footer .follow-us .pinterest {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    display: inline-block;
    background-size: cover;
    color: transparent;
}

footer .follow-us .fb {
    background-image: url('images/Facebook.svg');
}

footer .follow-us .twitter {
    background-image: url('images/Twitter.svg');
}

footer .follow-us .instagram {
    background-image: url('images/Instagram.svg');
}

footer .follow-us .pinterest {
    background-image: url('images/pinterest-inspira.png');
}

footer .follow-us .pinterest {
    background-image: url('images/pinterest-inspira.png');
}

.pinterest {
    width: 2.5rem;
    height: 2.5rem;
    line-height: normal;
    display: inline-block;
    background-size: cover;
    color: transparent;
    background-image: url('images/pinterest-inspira.png');
}

.img-responsive {
    margin-bottom: 1rem;
    /*width: 100%;*/
}

#search .form-control {
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    padding: 1.2rem 5rem;
    line-height: 2rem;
    height: initial;
    color: #838e97;
    margin: 1rem 0;
}

#search select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('images/arrow_down.png') right;
    padding-right: 20px;
}

@-moz-document url-prefix() {
    #search select {
        padding-top: 10px!important;
        padding-bottom: 9px!important;
    }
}

#search form div {
    padding: 0;
}

#search input[type="submit"] {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}

#search button[type="submit"] {
    font-size: 1.9rem !important;
    padding: 1rem !important;
}

#search input[type="submit"],
#search button[type="submit"],
#search-vertical input[type="submit"],
.price-info a:hover,
.price-info a:link,
.price-info a:visited,
.price-info a:active {
    font-size: 1.4rem;
    display: block;
    padding: 1.3rem;
    text-align: center;
    background-color: #465665;
    border: none;
    width: 100%;
    color: white;
}

#search a,
#search a:hover,
#search a:link,
#search a:visited,
#search a:active {
    color: white;
    font-size: 1.4rem;
    background-image: url(../images/bottom-arrow.png);
    background-repeat: no-repeat;
    padding-right: 2rem;
    background-position: right center;
}

#search input.check-in {
    /*background-image: url('images/check-in.png');
	background-repeat: no-repeat;
	background-position: 1rem center!important;
	padding-left: 4rem;*/
}

#search select.within {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 1rem !important;
}

#search select.price,
#search select.within,
#search .select-collapse,
#search .select-collapse:hover,
#search .select-collapse:not(.collapsed) {
    background-repeat: no-repeat;
    background-position: calc(100% - 2rem) center;
    padding-right: 4rem;
}

#search select.price,
#search select.within {
    background-image: url('images/arrow_down.png')!important;
}

#search .select-collapse.collapsed,
#search-vertical .select-collapse.collapsed {
    background-image: url('images/arrow_down.png')!important;
}

#search .select-collapse:not(.collapsed) {
    background-image: url('images/arrow_up.png')!important;
}

.bordered {
    background-color: #fff;
}

#search .select-collapse {
    padding: 1.2rem 2rem;
    line-height: 2rem;
}

#search input:focus,
#search select:focus,
#search .select-collapse:not(.collapsed) {
    outline: none;
    border-color: transparent;
    border-bottom: 2px solid #529ad3;
    box-shadow: none;
}

#search-vertical .select-collapse {
    display: block;
    font-size: 1.2rem;
    width: 100%;
    padding-left: 0.25rem;
}

#search-vertical .form-group .select-collapse {
    border-radius: 0.5rem!important;
    border: 1px solid #ccc;
    font-size: 1.4rem;
    height: 34px;
    padding: 6px 12px;
    background-position: calc(100% - 1rem) center;
}

.select-collapse.collapsed {
    background-image: url('images/arrow_up.png');
}

.select-collapse,
.select-collapse:link,
.select-collapse:hover,
.select-collapse:active,
.select-collapse:visited {
    width: 100%;
    background-image: url('images/arrow_down.png');
    background-color: white;
    border-radius: 0!important;
    background-repeat: no-repeat;
    background-position: calc(100% - 2rem) center;
    color: #838e97!important;
    text-align: left;
}

.select-collapse[aria-expanded="true"],
.select-collapse[aria-expanded="true"]:link,
.select-collapse[aria-expanded="true"]:hover,
.select-collapse[aria-expanded="true"]:active,
.select-collapse[aria-expanded="true"]:visited {
    background-image: url('images/arrow_up.png')!important;
}

#viewguest {
    position: absolute;
    width: 100%;
    margin-top: 2rem;
    color: #838e97;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -webkit-background-clip: padding-box;
}

#search-vertical #viewguest {
    border: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-background-clip: none;
}

#viewguest>div {
    padding: 1.2rem 2rem!important;
}

.arrow_box {
    position: relative;
    background: #FFF;
    line-height: 3rem;
}

.arrow_box:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #FFF;
    border-width: 15px;
    margin-left: -15px;
}

#discount-code-box {
    color: #ea732d;
    font-weight: bold;
    font-size: 1.2em;
}

#title-purchase-mobil {
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
    font-family: 'Poppins', Verdana, sans-serif;
    font-weight: 400;
    font-size: 1.4em;
}


/*.format-placeholder::-webkit-input-placeholder
{
	background-color: orange;
	color: white;
}
*/


/*#coupon::-webkit-input-placeholder {
	color: white;
}
#coupon::-webkit-input-placeholder {
	background-color: #ea732d;

}

#coupon::-moz-placeholder {
	color: white;
}
#coupon::-moz-placeholder {
	background-color: #ea732d;
}*/


/*.form-control-coupon
{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: white;
    background-color: #ea732d;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}*/


/*::-webkit-input-placeholder {
	background-color: #ea732d;
	color:  white;
}
:-ms-input-placeholder {
	background-color: #ea732d;
	color: white;
}
::-moz-placeholder {
	background-color: #ea732d;
	color: white;
}
:-moz-placeholder {
	background-color: #ea732d;
	color:  white;
}*/

#border-cards-purchase-mobil {
    width: 100%;
    border: .5px solid #ccc;
    border-radius: 4px;
    margin-top: 1em;
    margin-bottom: 1em;
}

#card-subtitle-format {
    font-size: 20px;
    font-family: 'Poppins-ligth', Verdana, sans-serif;
    color: #818487;
}

.row-bordered:before {
    content: "";
    display: block;
    border-bottom: 1px solid #ccc;
    border-color: #ea732d;
}

.row-bordered:after {
    content: "";
    display: block;
    border-bottom: 1px solid #ccc;
    border-color: #ea732d;
}

.row-bordered:before {
    margin-top: 10px;
}

.border-position {
    /*	margin-top: 2.1em;*/
}

.without-border {
    border: 0px!important;
}

.row-borderedCurrency:after {
    content: "";
    display: block;
    border-bottom: 1px solid #ccc;
    margin: 0 15px;
    border-color: #ea732d;
}

.row-borderedCurrency {
    border: .1rem solid #dededf;
}

#size-slider-whyinspira {
    background-image: url('images/piscina-panoramica-mobil.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

#carousel-tems-whyInspira {
    max-height: 450px
}

#caption-carousel-whyInspira {
    padding-left: 18%;
    padding-right: 18%;
    line-height: 1.5em;
}

.img-whyInspira-Mobil {
    padding: 16%;
}

#title-slider-why-inspira {
    font-size: 1.2em;
    color: #ffffff;
}

#text-slider-why-inspira {
    font-size: .8em;
    color: #ffffff;
    font-weight: 100;
}

#viewguest .rooms-quantity,
#viewguest .guests-quantity {
    display: inline-block;
    border: 1px solid #a9a9a9!important;
    border-radius: .25em;
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    vertical-align: middle;
    padding: 0rem;
    line-height: 2;
    font-size: 1.3rem;
}

#search-vertical #viewguest {
    position: relative;
}

#search-vertical .arrow_box:after {
    border-bottom-color: whitesmoke;
}

#search-vertical .arrow_box {
    background-color: whitesmoke;
}

#search-vertical #viewguest>div {
    padding: 1rem 0rem!important;
}

input[type=number] {
    text-align: center;
    -moz-appearance: textfield;
    width: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

span.minus,
span.plus {
    width: 2rem;
    cursor: pointer;
    /* background-color: whitesmoke; */
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    border-radius: 10px;
    color: #04416D;
    line-height: 2.4rem;
}

input[disabled="disabled"] {
    border: none;
}

span.minus:before {
    content: '- ';
}

span.plus:before {
    content: '+';
}


/*Common*/

.modal button.close {
    background-image: url('images/close-modal.png');
    background-repeat: no-repeat;
    height: 2.5rem;
    width: 2.5rem;
    display: block;
    cursor: pointer;
    background-size: cover;
    opacity: initial;
    margin-top: -2rem;
    margin-right: -2rem;
}

.modal-wrapper {
    background-color: #fff;
    padding: 5rem 2rem;
    margin-bottom: 1rem;
}

.modal-wrapper a {
    text-transform: capitalize;
}

.modal-wrapper div[data-role="submit"]:not(.btn-continue),
.modal-wrapper .do-register,
.modal-wrapper .do-fb {
    display: block;
    cursor: pointer;
    background-color: #04416D;
    padding: 1.4rem 2rem;
    color: white;
    border-radius: 0.2rem;
    text-align: center;
    font-size: 1.6rem;
    background-repeat: no-repeat;
    background-position: 1rem center;
    background-size: inherit;
    background-image: url('images/form/at-white.png');
}

.modal-wrapper h3 {
    font-size: 2rem;
    margin-top: 0;
}

#login-modal .modal-wrapper a:not(.do-fb):not(.do-register),
#register-options .modal-wrapper a:not(.do-fb):not(.do-register) {
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    color: #04416D;
    text-decoration: underline;
    font-weight: 200;
}

.modal-wrapper .form-control {
    padding: 1rem 2rem 1rem 5rem;
    height: initial;
    background-repeat: no-repeat;
    background-size: auto 3rem;
    background-position: 1rem center;
    -webkit-appearance: none;
}

.modal-wrapper .form-control.name {
    background-image: url('images/form/user-icon.png');
}

.modal-wrapper .form-control.email {
    background-image: url('images/form/email.png');
}

.modal-wrapper .form-control.pass,
.modal-wrapper .form-control.pass-confirm {
    background-image: url('images/form/password.png');
}

.modal-wrapper .form-control.currency {
    background-size: initial;
    background-image: url('images/form/currency.png');
}

.modal-wrapper .form-control.language {
    background-size: initial;
    background-image: url('images/form/message.png');
}

.modal-wrapper .form-control.discount {
    background-size: initial;
    background-image: url('images/form/discount.png');
}

.modal-wrapper .form-control.code {
    background-size: initial;
    background-image: url('images/form/access.png');
}

.modal-wrapper hr {
    padding: 0rem;
    margin-top: 1rem;
}

.modal-wrapper .btn-modal {
    border: none;
    width: 100%;
    padding: 1rem;
    font-size: 1.6rem;
    display: block;
    color: white;
    background-color: #9BA4AB;
}

.do-fb {
    background-color: #529ad3!important;
    padding: 1rem 2rem;
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 1.5rem;
    color: white!important;
    text-decoration: none!important;
    background-image: url('images/fb-icon.png')!important;
}


/******/

.profile .avatar {}

.profile div.subtitle,
.wallet div.subtitle {
    padding: 3rem;
    font-size: 2.8rem;
}

.wallet p {
    color: #838e97;
}

.wallet .details-box {
    font-size: 1.2rem;
    min-height: 46rem;
}

.wallet .details-box .description {
    height: 100%;
    min-height: 140px;
}

.wallet .details-box select {
    background-color: transparent;
    border-radius: 0.2rem;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.wallet .modal input {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

#details-cash span.pending,
#wallet span.pending {
    color: #cc4b9b;
}

#details-certs span.pending,
#certs span.pending {
    color: #529ad3;
}

#details-cards span.pending,
#cards span.pending {
    color: #002f87;
}

.wallet .details-box span.small-letters {
    display: block;
    margin-top: 0.5rem;
}

.no-results {
    vertical-align: middle;
    line-height: 15;
}

.text-big {
    font-size: 1.6rem;
    padding: 1rem 0;
}

.title-icon {
    height: 100%;
    max-height: 50px;
    margin-bottom: 1.5rem;
}

.details-box {
    background-color: white;
    border: .1rem solid #dededf;
    margin: 5px;
    padding: 3rem 2rem;
    color: #04416D;
}

.details-box h3 {
    font-size: 2rem;
    margin-top: 0;
    padding-top: 0;
}

.details-box a[data-role="change"],
.details-box a[data-role="change"]:link,
.details-box a[data-role="change"]:hover,
.details-box a[data-role="change"]:active,
.details-box a[data-role="change"]:visited,
.details-box div[data-role="submit"],
.details-box#send-facebook div.fb-share,
.details-box#send-facebook div.fb-share:link,
.details-box#send-facebook div.fb-share:hover,
.details-box#send-facebook div.fb-share:active,
.details-box#send-facebook div.fb-share:visited,
.details-box div[data-role="submit"]:link,
.details-box div[data-role="submit"]:hover,
.details-box div[data-role="submit"]:active,
.details-box div[data-role="submit"]:visited,
#avatar a[data-role="change"],
#avatar div[data-role="submit"] {
    width: 100%;
    background-color: #9ba4ab;
    display: block;
    color: white;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    font-size: 1.6rem;
    border-radius: 0.2rem;
}

.details-box.informative-total a[data-role="change"] {
    margin-top: 2rem;
}

.details-box.informative-total .hd {
    font-size: 1.6rem;
    color: #A0A9B1;
    font-weight: 400;
}

.details-box.informative-total .small-letters strong:first-child {
    margin-bottom: 2rem;
    display: block;
}

#avatar div[data-role="submit"] {
    margin-top: 1rem;
}

.details-box .form-data strong {
    color: #9099a2;
    font-weight: 300;
}

.details-box .input-group {
    width: 100%;
}

#facebook .fb-linkto a:first-child {
    padding: 1rem 2rem;
    background-color: #529ad3;
    color: white;
    width: 100%;
    display: block;
    font-size: 1.6rem;
    padding-right: 4rem;
    background-position: calc(100% - 1rem) center;
    background-repeat: no-repeat;
    background-image: url('images/fb-icon.png');
    background-size: 2.5rem;
}

a.small,
a.small:hover,
a.small:focus {
    color: gray;
    font-size: 1.3rem;
    line-height: 4rem;
}

a.link-right {
    color: inherit;
}

a.link-right:after {
    content: "  \003E"
}

.nopadding {
    padding: 0;
}

.nopadding-left {
    padding-left: 0;
}

.nopadding-right {
    padding-right: 0;
}

.profile #wrapper,
.refer #wrapper,
.wallet #wrapper,
.concierge #wrapper,
.weekly-stays #wrapper,
.about-us #wrapper,
.contact-us #wrapper,
.history #wrapper {
    background-color: #fafafc;
    padding-bottom: 10rem;
    font-size: 1.6rem;
}

.refer #wrapper>.container,
.profile #wrapper>.container,
.wallet #wrapper>.container,
.concierge #wrapper>.container,
.weekly-stays #wrapper>.container,
.about-us #wrapper>.container,
.contact-us #wrapper>.container,
.history #wrapper>.container {
    padding-top: 5rem;
}

.informative #wrapper>.container {
    line-height: 2.5rem;
}

.concierge #wrapper>.container ul {
    padding-top: 3rem;
}

.concierge #wrapper>.container strong {
    font-size: 2.3rem;
}

.informative #wrapper>.container p {
    padding-bottom: 1.5rem;
}


/**/

.inputfile {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    color: white;
    background-color: #04416D;
    display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: black;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
    width: 100%;
    margin-top: 2rem;
}

.inputfile:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 0.5rem;
}

.breadcrumb {
    background-color: white;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.breadcrumb a,
.breadcrumb a:hover,
.breadcrumb a:visited,
.breadcrumb a:active,
.breadcrumb a:link {
    color: #4f4f4f;
    font-size: 1.4rem;
}


/**********/

.head-belt {
    display: block;
    height: 25rem;
    background-position: center center;
    background-size: cover;
    color: white;
    padding: 5rem 0;
    font-size: 3rem;
}

.head-belt h2 {
    font-size: 5rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.head-belt small {
    font-size: 1.8rem;
}

.profile .head-belt {
    background-image: url('images/profile-header.png');
    background-position: top center;
}

.refer .head-belt {
    background-image: url('images/refer-header.png');
    background-position: bottom center;
}

.concierge .head-belt {
    background-image: url('images/concierge-header.png');
}

.weekly-stays .head-belt {
    background-image: url('images/weekly-stays-header.png');
}

.about-us .head-belt {
    background-image: url('images/about-header.jpg');
}

.wallet .head-belt {
    background-image: url('images/refer-header.png');
    background-position: bottom;
}

.contact-us .head-belt {
    background-image: url('images/contact-header.png');
    background-position: bottom;
}

.history .head-belt {
    background-image: url('images/history-header.png');
    background-position: bottom;
}

.note {
    font-size: 1.2rem;
    text-align: left;
    color: gray;
}


/** send invite **/

.details-box#send-invite div[data-role="submit"],
.details-box#send-facebook div[data-role="submit"],
.details-box#send-facebook div.fb-share {
    margin-top: 0!important;
    border-radius: 0.2rem;
    background-color: #04416D!important;
    padding: 1rem!important;
}

.details-box#send-facebook div.fb-share {
    margin-top: 0!important;
    border-radius: 0.2rem;
    padding: 1rem!important;
    background-color: #529ad3!important;
    background-image: url(images/facebook_f.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    padding-right: 3rem!important;
    border-radius: 0.2rem!important;
    cursor: pointer!important;
    width: 100%;
    background-color: #9ba4ab;
    display: block;
    color: white!important;
    text-align: center;
    padding: 1rem 2rem;
    font-size: 1.6rem;
}

.details-box#send-facebook div[data-role="submit"],
.details-box#send-facebook div.fb-share {
    background-color: #529ad3!important;
    background-image: url(images/facebook_f.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    padding-right: 3rem!important;
}

.details-box#send-invite input[type="text"],
.details-box#send-facebook input[type="text"] {
    border-radius: 0.2rem!important;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    height: initial;
}

.details-box#send-invite .form-group {
    padding-bottom: 0;
    margin-bottom: 0.5rem;
}

.details-box#send-invite h3,
.details-box#send-facebook h3 {
    padding: 0rem!important;
}

.details-box#send-invite .alert-danger {
    margin-bottom: 0;
}

.details-box#send-facebook .form-group {
    margin-bottom: 2.2rem;
}

.nopadding-left {
    padding-left: 0!important;
}


/****/

.space {
    margin-top: 2rem;
    margin-bottom: 2rem;
}


/**********/

#search-vertical {
    color: #97a0a8;
    /* padding-top: .2rem; */
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon,
.radio label input[type="radio"]+.cr>.cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.radio label input[type="radio"]:checked+.cr>.cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: .5;
}

.checkbox i.destination-name {
    display: inline-block;
    width: 160px;
    vertical-align: top;
    white-space: pre-wrap;
    overflow: inherit;
}

@media (max-width: 1200px) {
    .checkbox i.destination-name {
        width: 110px;
    }
}


/***/

#search-vertical div[data-role="submit"],
#search-vertical input[type="submit"],
#search input[type="submit"],
#search-vertical input[type="submit"],
.btn-modal,
.details-box a[data-role="change"] {
    border-radius: 0.2rem!important;
    cursor: pointer!important;
}

#search-vertical .form-control {
    color: #838e97!important;
}

#search-vertical a[data-target="#more-options"] {
    margin-top: 2rem;
    text-align: right;
    padding-right: 4rem;
    margin-bottom: 0!important;
}

#search-vertical a.select-collapse:not(.collapsed) {
    margin-bottom: 1.8rem;
}

#search-vertical input.check-in {
    /*background-image: url('images/check-in.png');
	background-repeat: no-repeat;
	background-position: calc(100% - 1rem) center;
	padding-right: 4rem;*/
}

#search-vertical .details-box {
    padding: 1.5rem 0rem;
}

#search-vertical .details-box label {
    /* color: #97a0a8; */
    font-size: 1.2rem;
}

#search-vertical div[data-role="submit"],
#search-vertical input[type="submit"] {
    background-color: #04416D;
    color: white;
}

.checkout .resort-info {
    margin-bottom: 1rem;
}

.search-result .resort-info {
    border: 1px solid #c9c9c9!important;
    margin-bottom: 1rem;
}

.search-result .resort-info div:last-child(.fit-content) {
    margin: 0!important;
}

.search-result .pagination-box {
    border: 1px solid #c9c9c9!important;
    padding: 10px 0;
    margin-bottom: 2rem;
}

.search-result .pagination-box label {
    color: #97a0a8;
    margin-top: 5px;
}

.search-result .pagination-box .pagination {
    margin: 0!important;
}

.search-result #wrapper .nav-tabs {
    margin-bottom: 2rem;
}

.search-result #wrapper .nav-tabs>li>a {
    /*border-color:#d3d7da;*/
    /*background-color:#fafafc;*/
    color: #818d96;
    border-left: 1px solid #d3d7da;
    border-top: 1px solid #d3d7da;
    border-right: 1px solid #d3d7da;
}

.search-result #wrapper .nav-tabs>li.active>a,
.search-result #wrapper .nav-tabs>li.active {
    /*border-bottom:white;
    background-color:transparent;*/
    color: #e77326;
    font-weight: 600;
}

.search-result #wrapper .nav-tabs>li.active>a:before {
    content: '\00B7\00a0';
}

.search-result #wrapper .nav-tabs>li a.hot-weeks {
    padding-right: 4rem!important;
    background-image: url('images/hot-weeks-icon.png');
}

.search-result #wrapper .nav-tabs>li.active a.hot-weeks {
    background-image: url('images/hot-weeks-icon-active.png');
}

.search-result #wrapper .nav-tabs>li a.all-inclusive {
    background-image: url('images/all-inclusive-icon.png');
}

.search-result #wrapper .nav-tabs>li.active a.all-inclusive {
    background-image: url('images/all-inclusive-icon-active.png');
}

.search-result #wrapper .nav-tabs>li a.all-inclusive,
.search-result #wrapper .nav-tabs>li a.hot-weeks {
    padding-right: 5rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1.5rem) center;
}

.resort-info .img-responsive {
    margin: 0!important;
    object-fit: cover;
    height: 100%;
    max-height: 180px;
}

.resort-info h3 {
    font-size: 1.6rem!important;
    padding: 0;
    margin: 1.5rem 0 .5rem 0;
    color: #89959F;
    /*
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 4.5rem;
    overflow: hidden;
*/
}

.resort-info .description,
.resort-info .price-info {
    padding-left: 6%;
    font-size: 1.3rem;
    padding-bottom: 1rem;
    font-family: poppins;
    letter-spacing: 1px;
}

/* .price-info-list {
  padding-left: 6%;
  font-size: 1.3rem;
  padding-bottom: 1rem;
  line-height: 200%;
  font-family: poppins;
  letter-spacing: 1px;
}

.resort-info .price-info-list {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: right;
} */

.resort-info .avg,
.resort-info .retail {
    color: #909aa2;
    font-size: 1.2rem;
    margin-top: 0;
    padding-top: 0;
}

.resort-info .avg {
    margin-bottom: 2rem;
}

.resort-info .discount,
.resort .discount {
    color: #ea732d;
    font-weight: 600;
    font-size: 1.4rem;
}

.resort-info .price {
    padding: 0.5rem 0;
}

.resort .breadcrumb .container {
    padding: 0!important;
}

#list .discount span,
.resort-info .discount span,
.wallet-options strong span,
table th.wallet span,
.resort .discount span,
.checkout .discount span,
.checkout .coupon span,
.history .discount span {
    background-image: url('images/wallet_orange.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.history .discount span {
    background-image: url('images/wallet_orange.png');
}

.history span#coupon {
    background-size: contain;
    width: 16px;
    margin-right: 3px;
}

.checkout .coupon span,
.checkout #coupon,
.history span#coupon {
    background-image: url('images/coupon-orange.png');
}

.checkout input#coupon {
    background-repeat: no-repeat;
    background-position: 1rem center;
    padding-left: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ea732d;
    font-weight: 600;
    height: auto;
    /*    background-color: #ea732d;
*/
    /*    -webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%)!important;*/
}

.checkout input#coupon::-webkit-input-placeholder {
    color: #ea732d;
}

table th.wallet:before {
    content: "- ";
    color: #ea732d;
}

.price-info .total {
    font-size: 2.4rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.price-info a {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}

.resort-info .description .map {
    font-size: 1.2rem;
}

.resort-info .description .map span {
    background-image: url(images/loc_orange.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2rem;
}

.resort-info .description a span {
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.6rem;
    height: 1rem;
    display: inline-block;
    vertical-align: inherit;
    margin-left: .2rem;
}

.resort-info .description a[data-role="load-modal"]:link span,
.resort-info .description a[data-toggle="collapse"]:link span {
    background-image: url(images/plus.png);
}

.resort-info .description a[data-role="load-modal"]:active span,
.resort-info .description a[data-role="load-modal"]:visited,
.resort-info .description a[data-role="load-modal"]:hover span {
    background-image: url(images/minus.png)!important;
}

.resort-info .description a[aria-expanded="true"] span {
    background-image: url(images/minus.png)!important;
}

.rating {
    font-size: 1.2rem;
    line-height: .5rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    color: #a6a6a6;
}

.rating span.stars {
    font-size: 1.3rem;
    letter-spacing: 0rem;
    margin-bottom: 0.5rem;
}

.rating span,
span.faces,
span.stars {
    font-size: 1rem;
    display: inline-block;
    width: auto;
    letter-spacing: 0.1rem;
    vertical-align: middle;
}

span.face-1:after {
    content: '☺';
}

span.face-2:after {
    content: '☺☺';
}

span.face-3:after {
    content: '☺☺☺';
}

span.face-4:after {
    content: '☺☺☺☺';
}

span.face-5:after {
    content: '☺☺☺☺☺';
}

span.star-1:after {
    content: "\2605";
}

span.star-2:after {
    content: "\2605\2605";
}

span.star-3:after {
    content: "\2605\2605\2605";
}

span.star-4:after {
    content: "\2605\2605\2605\2605";
}

span.star-5:after {
    content: "\2605\2605\2605\2605\2605";
}

.resort-info .description a {
    color: #6d7984;
}

span.star-6:after {
    content: '★★★★★★';
}

span.star-7:after {
    content: '★★★★★★★';
}


/**/

#map-preview span {
    color: white;
    background-color: #04416D;
    padding: 0.5rem;
    width: 12rem;
    text-align: center;
    bottom: 1rem;
    right: 0rem;
    position: absolute;
    text-transform: uppercase;
    background-image: url(images/loc_orange.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) 0.8rem;
}


/**/

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #e1e4e6;
    border-radius: 0.25rem;
    color: #97a0a8;
    border: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #97a0a8;
    border: none!important;
}

ul.sort-by {
    display: inline-block;
    margin: 0;
}

ul.sort-by li {
    display: inline-block;
}

ul.sort-by a[data-] {}

.wallet-options {
    padding: 1rem;
    border: 1px solid #c9c9c9;
    margin-bottom: 1rem;
    background-color: #fafafc;
    font-size: 1.2rem;
    color: #929ba4;
}

.wallet-options strong {
    color: #ea732d;
}

.wallet-options {
    padding: 1.5rem 1rem;
    font-size: 1.1rem;
    line-height: normal;
    vertical-align: middle;
}

.wallet-options .cr {
    border-color: #ea732d;
    float: initial;
    vertical-align: text-bottom;
}

.wallet-options .cr-icon,
.wallet-options .cr {
    color: #ea732d;
    font-size: 1.4rem;
}

.wallet-options .quantity {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.wallet-options .quantity_certs {
    color: #929ba4;
    text-transform: uppercase;
    font-weight: 600;
}

.wallet-options #fund .quantity {
    color: #5a6875;
}

.wallet-options #fund .quantity strong,
.wallet-options #cash .quantity strong {
    border: 1px solid #65727e;
    padding: 0.2rem 0.4rem;
    font-size: 1rem;
    margin-right: 0.5rem;
    border-radius: 0.2rem;
    text-transform: uppercase;
}

.wallet-options .quantity strong,
.wallet-options .quantity_certs strong {
    color: #65727e!important;
}

.wallet-options .quantity i,
.wallet-options .quantity_certs i,
.wallet-options a[aria-expanded="true"] {
    width: 2rem;
    height: 1.8rem;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.3rem;
    background-position: center center;
}

.wallet-options #fund .quantity i,
.wallet-options #fund .quantity_certs i {
    background-image: url(images/wallet/vacation-fund.png);
}

.wallet-options #discount .quantity i,
.wallet-options #discount .quantity_certs i {
    background-image: url(images/wallet/discount-codes.png);
}

.wallet-options #certs .quantity i,
.wallet-options #certs .quantity_certs i {
    background-image: url(images/wallet/certificates.png);
}

.wallet-options #cash .quantity i,
.wallet-options #cash .quantity_certs i {
    background-image: url(images/wallet/cash.png);
}

.wallet-options #cards .quantity i,
.wallet-options #cards .quantity_certs i {
    background-image: url(images/wallet/vacation-cards.png);
}

.wallet-options #points .quantity i,
.wallet-options #points .quantity_certs i {
    background-image: url(images/wallet/points.png);
}

.wallet-options .radio,
.wallet-options .checkbox {
    margin: 0;
}

.wallet-options li {
    display: table-cell;
    text-align: center;
}

.wallet-options ul {
    display: table;
    width: 100%;
    margin-bottom: 0;
}

.wallet-options .radio label,
.wallet-options .checkbox label {
    padding: 0;
}

.wallet-options a.select-collapse {
    width: 1.2rem;
    background-position: center;
    height: 1.4rem;
}

.wallet-options select[name="currency"] {
    background-color: transparent;
    border-radius: 0.2rem;
    font-weight: 600;
}

.wallet-options input[name="cash_amount"] {
    background-image: none!important;
    border: 1px solid;
    border-radius: 0.2rem;
    width: 3rem;
    padding: 0.1rem;
    vertical-align: inherit;
    font-size: 1.1rem;
}

/* .wallet-options .quantity {
    text-transform: inherit;
} */

.wallet-options #certs {
    text-align: right;
}

.wallet-options .custom-tooltip {
    position: relative;
    display: inline-block;
    color: #ba4c94;
    background-image: url('images/tooltip_info.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 1rem;
    vertical-align: initial;
    height: 1rem;
}

.wallet-options .custom-tooltip .tooltiptext {
    visibility: hidden;
    width: 12rem;
    background-color: white;
    color: #ba4c94;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    top: 50%;
    font-size: 1.2rem;
    right: 0;
    margin-right: -12rem;
    opacity: 0;
    transition: opacity 1s;
    border-radius: 2px;
    border: 1px solid rgba(215, 215, 215, 0.8);
    box-shadow: 1px 1px 1px rgba(215, 215, 215, 0.8);
}

.wallet-options .custom-tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.wallet-options .tooltiptext strong {
    color: inherit;
}

.sort-by {
    width: calc(100% - 1rem);
    display: table;
    border: 1px solid #c9c9c9;
    margin-bottom: 1rem;
    background-color: #fafafc;
    font-size: 1.2rem;
    color: #929ba4;
    margin-left: 1rem;
}

.sort-by ul {
    display: table-row;
}

.sort-by ul li {
    display: table-cell;
    padding: 1.4rem;
}

.sort-by ul li:first-child {
    text-align: right;
    width: 30%;
}

.sort-by li[data-toggle="collapse"] {
    color: transparent;
    padding: 1rem;
    min-width: 100px;
    width: 20%;
}

.sort-by ul li.active a {
    text-decoration: underline;
}

.sort-by li.collapsed,
.view-map div.collapsed {
    cursor: pointer;
    background-color: #ea732d;
    text-align: center;
    border-radius: 0.2rem 0 0 0.2rem;
    -webkit-transition: all 0.2s;
    /* Safari */
    transition: all 0.2s;
    background-image: url(images/wallet-icon.png), url(images/down_arrow_white.png);
    background-repeat: no-repeat;
    background-position: calc(50% - 2rem) center, calc(100% - 2rem) center;
}

.sort_by_map {
    font-size: 1.2rem;
    /* position: absolute; */
    top: .5rem;
}

.sort_by_map ul {
    display: table-row;
    float: right;
}

.sort_by_map ul li {
    display: table-cell;
    padding: .8rem;
}

.sort_by_map ul li:first-child {
    text-align: right;
    /*width:30%;*/
}

.sort_by_map li[data-toggle="collapse"] {
    color: transparent;
    padding: 1rem;
    min-width: 100px;
    width: 20%;
}

.sort_by_map ul li.active a {
    text-decoration: underline;
}

.sort_by_map li.collapsed {
    cursor: pointer;
    background-color: #ea732d;
    text-align: center;
    border-radius: 0.2rem 0 0 0.2rem;
    -webkit-transition: all 0.2s;
    /* Safari */
    transition: all 0.2s;
    background-image: url(images/wallet-icon.png), url(images/down_arrow_white.png);
    background-repeat: no-repeat;
    background-position: calc(50% - 2rem) center, calc(100% - 2rem) center;
}


/**/

.view-map #autocomplete {
    background-image: url('images/desination-blue-icon.png');
    background-repeat: no-repeat;
    background-position: 3rem center;
}

.view-map .form_datetime {
    background-image: url('images/check-in-blue.png')!important;
    background-repeat: no-repeat;
    background-position: 3rem center!important;
}

.view-map .map-wls {
    position: absolute;
    bottom: 1rem;
    left: 0rem;
    font-size: 1.2rem;
}

.view-map .map-wls ul {
    /*display: inline-block;
    margin-left: 2rem;
    background-color: #f3f8fe;
    -webkit-box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
    border-radius: 0.3rem;*/
}

.view-map .map-wls ul.mobile {
    display: inline-block;
    background: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: none !important;
}

.map-wls li {
    display: inline-block;
    padding: 0rem 2rem 0rem 0;
    margin: 0;
    font-weight: 600;
}

.map-wls li.mobile {
    border-radius: 2.5rem;
    background-color: #fff;
    -webkit-box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.75);
}

.map-wls li.mobile .cr,
.map-wls li.mobile .category {
    display: none;
}

.map-wls li:not(:last-child) {
    /*border-right: 1px solid #bbbec1;*/
}

.map-wls li:nth-child(1) label:not(span) {
    color: #a4ce3a;
}

.map-wls li:nth-child(2) label:not(span) {
    color: #cc4b9b;
}

.map-wls li:nth-child(3) label:not(span) {
    color: #529ad3;
}

.map-wls li:nth-child(4) label:not(span) {
    color: #ea732d;
}

.map-wls li.mobile.m_all_inclusive {
    border: 2px solid #ea732d;
}

.map-wls li.mobile.m_hot_weeks {
    border: 2px solid #529ad3;
}

.map-wls li.mobile.m_flat_rate_weeks {
    border: 2px solid #cc4b9b;
}

.map-wls li.mobile.m_bonus_weeks {
    border: 2px solid #a4ce3a;
}

.map-wls li.mobile.m_resort {
    border: 2px solid #04416D;
}

.view-map a[data-role="change"] {
    margin-top: 0!important;
}

.view-map .wallet-options {
    font-size: 1rem;
}

.view-map .list-view {
    width: 100%;
    display: block;
    background-color: #dcdfe1;
    font-weight: 600;
    padding: 2.6rem 2rem 2.6rem 3rem;
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    border-radius: 0.2rem 0 0 0.2rem!important;
    border-right: 1px solid #bbbcbd;
    background-image: url('images/list-item.png');
    background-repeat: no-repeat;
    background-position: calc(50% - 5rem) center;
}

.view-map .check-in,
.view-map .form-control.destination {
    padding: 2rem 2rem 2rem 6rem;
    margin: 1.3rem 0;
    display: inline-block;
    width: 40%;
}

.view-map .form-control.destination {
    border-radius: 0.3rem 0 0 0.3rem!important;
    margin: 1.3rem 0 1.3rem 1.3rem;
    border-right: 0;
}

.view-map .check-in {
    border-radius: 0 .3rem .3rem 0!important;
}

.view-map .wallet-options {
    padding: 0;
}

.search-map {
    background-color: #dcdfe1;
}

.view-map .wallet-options ul {
    margin: 1.5rem 0;
    padding-right: 1rem;
}

.view-map .wallet-options a.select-collapse {
    background-image: url(images/arrow_right.png);
}

.view-map div[data-toggle="collapse"] {
    color: transparent;
    padding: 1rem;
    display: none;
}

.view-map div.collapsed {
    display: block;
    width: 100%;
    max-width: 100px;
    text-align: right;
    float: right;
    padding: 1.7rem 1rem;
    background-image: url(images/left_arrow_white.png), url(images/wallet-icon.png);
    background-repeat: no-repeat;
    background-position: calc(50% - 2rem) center, calc(100% - 2rem) center;
}

.view-map #search-vertical input[data-role="submit"] {
    color: transparent;
}


/***/

.ui-autocomplete {
    font-family: 'Poppins', Verdana, sans-serif;
    font-size: 1.4rem!important;
    color: #04416D;
    padding-bottom: 0.5rem!important;
    padding-top: 0.5rem!important;
    z-index: 10;
}

.destination-input i {
    position: absolute;
    margin: 0.5rem;
    min-width: 5.6rem;
    text-align: center;
}

#stype-country .destination-input i,
#stype-country .checkbox i.category,
#stype-country .ui-menu-item a.ui-corner-all i {
    min-width: 0.5rem!important;
    font-size: 0px!important;
    padding: 12px!important;
}

#stype-country .destination-input i:not(:empty)~input {
    padding-left: 3.5rem!important;
}

#stype-country .destination-input i,
#stype-country .ui-menu-item a.ui-corner-all i,
#stype-country .destination-input i.city,
.checkbox i {
    background-position: center center;
    background-size: 20px;
    background-repeat: no-repeat;
}

#stype-country .destination-input .city,
#stype-country .ui-menu-item a.ui-corner-all i.city,
#stype-country .destination-input i.city,
#stype-country .checkbox i.city {
    background-image: url('images/city.png');
}

#stype-country .ui-menu-item a.ui-corner-all i.region,
#stype-country .checkbox i.region,
#stype-country .destination-input i.region {
    background-image: url('images/region.png');
}

#stype-country .ui-menu-item a.ui-corner-all i.area,
#stype-country .checkbox i.area,
#stype-country .destination-input i.area {
    background-image: url('images/area.png');
}

.destination-input i:not(:empty)~input {
    padding-left: 6.5rem;
}

.destination-input i:empty~input {
    padding-left: 0.5rem;
}

.ui-menu-item a.ui-corner-all i,
.destination-input i {
    background-color: #fafafc;
    border-radius: 0.2rem;
    color: #04416D;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    text-transform: uppercase;
    font-size: 1rem;
    vertical-align: middle;
}

.checkbox i.category {
    color: white;
    padding: 0.2rem 0.5rem!important;
    font-size: 1rem;
    text-transform: uppercase;
    vertical-align: middle;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.checkbox i.weeks {
    font-size: 1rem;
    vertical-align: middle;
}

.ui-menu .ui-menu-item {
    margin-bottom: 0.2rem!important;
}

.ui-menu .ui-menu-item a {
    color: #566572!important;
}

.ui-menu-item a.ui-corner-all i,
.destination-input i {
    color: white;
}

.ui-menu-item a.ui-corner-all i.country,
.destination-input i.country {
    background-color: #cc4b9b;
}

.ui-menu-item a.ui-corner-all:hover i.country,
.ui-state-focus i.country {
    background-color: #a73c7e;
}

.ui-menu-item a.ui-corner-all i.city,
.destination-input i.city,
.checkbox i.city {
    background-color: #ea732d;
}

.ui-menu-item a.ui-corner-all:hover i.city,
.ui-state-focus i.city {
    background-color: #b65821;
}

.ui-menu-item a.ui-corner-all i.region,
.checkbox i.region,
.destination-input i.region {
    background-color: #a4ce3a;
}

.ui-menu-item a.ui-corner-all:hover i.region,
.ui-state-focus i.region {
    background-color: #80a12c;
}

.ui-menu-item a.ui-corner-all i.other,
.ui-menu-item a.ui-corner-all i.area,
.ui-menu-item a.ui-corner-all i.resort,
.destination-input i.other,
.destination-input i.area,
.destination-input i.resort,
.checkbox i.resort,
.checkbox i.area,
.checkbox i.other {
    background-color: #529ad3;
}

.ui-menu-item a.ui-corner-all:hover i.other,
.ui-menu-item a.ui-corner-all:hover i.resort,
.ui-menu-item a.ui-corner-all:hover i.area,
.ui-state-focus i.area,
.ui-state-focus i.resort,
.ui-state-focus i.other {
    background-color: #3e749f;
}

.ui-menu .ui-menu-item a:hover,
.ui-menu .ui-menu-item a:active,
.ui-menu .ui-menu-item a:link,
.ui-menu .ui-menu-item a:focus {
    background-color: transparent!important;
    border: none!important;
    background-image: none!important;
}

.ui-menu .ui-menu-item a:hover i {
    background-color: #ececee;
}

.ui-menu .ui-menu-item a {
    color: #566572;
}


/**/

.availability table {
    margin-bottom: 1rem;
}

.availability table th {
    font-size: 1.4rem!important;
}

.availability table td,
.availability table th {
    text-align: center;
    color: #6d7984!important;
}

.availability table td {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
}

.availability table tbody {
    font-size: 1.2rem;
}

.availability table tbody td:first-child,
.availability table thead th:first-child {
    padding-left: 5rem;
}

.availability table tbody td:last-child,
.availability table thead th:last-child {
    padding-right: 5rem;
}

.savings {
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    color: #ea732d;
}

.availability table thead {
    background-color: #f2f3f4;
    border: none;
    font-size: 1.4rem;
}

.availability table thead th {
    text-align: center;
    padding: 3rem 1rem 0 1rem;
    font-size: 1.4rem!important;
}

.availability .table>thead>tr>th,
.availability .table>tbody>tr>th,
.availability .table>tfoot>tr>th,
.availability .table>thead>tr>td,
.availability .table>tbody>tr>td,
.availability .table>tfoot>tr>td {
    border: none!important;
    font-size: 1.2rem;
}

.availability .table>thead>tr>th:last-child {
    padding: 0;
    position: relative;
}

.availability .table>thead>tr>th:last-child a {
    background-image: url(images/close-small.png);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
}

.availability a {
    text-decoration: underline;
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
}

.collapse-close {
    position: absolute;
    top: 0;
    right: 0;
    color: transparent!important;
}


/**/

.resort .table {
    border: 1px solid #e0e0e0;
    margin-bottom: 0px;
}


/*
	.resort div[data-id="rooms"] div[data-role="response"]:not(:last-child) .table {
		border-bottom: 2rem solid #f4f5f6;
	}
*/

.resort .table th {
    font-size: 2rem;
    color: #04416D;
    text-align: center;
    text-transform: uppercase;
}

.resort .table th,
.resort .table td {
    border: 1px solid #ddd;
}

.resort .table tr {
    color: #7C8791;
    font-size: 1.6rem;
}

.resort .table tr:not(:first-child) {
    border-top: 2rem solid #f4f5f6;
}

.resort .table td {
    padding: 15px 0 5px 0;
    vertical-align: middle;
}

.room-type .description {
    color: #959ea6;
}

.room-type .description .short {
    font-size: 1.8rem;
    color: #04416D;
    /* 	    padding-bottom: 1.5rem; */
    display: block;
}

.room-type .description span.adults,
.room-type .description span.children,
.room-type .description span.beds {
    display: block;
    content: " ";
    background-size: cover;
}

.room-type .description .additional {
    font-size: 1.4rem;
    padding-left: 0.5rem;
    margin-top: 1rem;
}

.room-type .description span.adults {
    background-image: url(images/adults-1.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: initial;
    height: 4rem;
    display: inline-block;
    vertical-align: middle;
}

.room-type .description span.children {
    background-image: url(images/adults.png);
    background-repeat: no-repeat;
    display: inline-table;
    height: 3rem;
    margin-top: 0.8rem;
}

.room-type .description span.beds {
    background-image: url(images/bed.png);
    background-repeat: no-repeat;
    height: 1.5rem;
    background-size: initial;
    display: inline-block;
    vertical-align: middle;
    background-position: center center;
}

.room-type .description span.baths {
    background-image: url(images/baths.png);
    background-repeat: no-repeat;
    height: 4rem;
    background-size: initial;
    display: inline-block;
    vertical-align: middle;
    background-position: center center;
}

.room-type .description span.bath-1,
.room-type .description span.beds-1,
.room-type .description span.adults-1 {
    width: 4rem!important;
}

.room-type .description .form-group {
    margin-bottom: 1rem;
    color: #7a858f;
}

.resort #wrapper hr {
    border-top: 20px solid #eee;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.resort .discount {
    font-size: 1.4rem;
    font-family: 'Poppins', Verdana, sans-serif;
    font-weight: 600;
    margin-top: 0.6rem;
}

.resort .discount span {
    width: 2.5rem;
    height: 2.5rem;
}

.resort .retail {
    font-size: 1.2rem;
}

.resort .avg {
    font-size: 1.3rem;
    color: #a1a9b0;
    margin-bottom: 2rem;
}

.resort .price-info .total {
    font-size: 3rem;
    font-weight: 600;
    padding-bottom: .5rem;
    margin: 0;
    padding: 0;
    color: inherit;
}

.resort .price-info .total.small {
    font-size: 2rem!important;
}

.resort .final-price {
    padding: 0 1.5rem 1rem 1.5rem;
}

.resort .price-info .alert-room {
    color: #EA732D;
    font-size: 1.6rem;
    margin: 0;
}

.resort .final-price .total {
    font-size: 4rem;
    color: #04416D;
}

.resort .final-price .total.small {
    font-size: 3rem!important;
}

.resort h3 {
    margin: 0;
    padding: 5% 1rem 0 0;
}

.resort div.rating {
    font-size: 1.3rem;
    padding-bottom: 0;
    line-height: 2rem;
    padding-top: 0;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.resort div.rating span[class*='face'] {
    font-size: 1.6em;
    letter-spacing: 0.5rem;
}

.resort div.inventory-address {
    font-size: 1.4rem;
    color: #707070;
}

.resort div.inventory-address .check {
    font-size: 1.4rem;
    font-family: 'Poppins', Verdana, sans-serif;
    margin-bottom: 1.5rem;
    font-weight: 800;
    margin-top: 1rem;
    color: #04416D;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
    height: 100px;
}

.resort .img-responsive {
    margin-bottom: 0;
}

.resort .table td {
    padding: 15px;
}

.resort-options {
    background-color: #ECEEEF;
    /* margin-bottom: 1rem; */
    padding: 2rem;
}

.resort-options ul {
    width: 100%;
    margin: 0;
}

.resort-options ul li {
    display: inline-block;
}

.resort-options ul li:not(:first-child) {
    margin-left: 1.5rem;
}

.resort-options .price {
    text-align: center;
}

.resort-options .price input {
    width: 4rem;
}

.resort a.btn-reserve,
.resort a.btn-hold {
    color: white!important;
    border-radius: 0.2rem!important;
    display: block;
    font-size: 1.8rem;
    padding: 0.8rem;
    text-transform: none!important;
    margin: 0.5rem 0;
    font-family: 'Poppins', Verdana, sans-serif!important;
    text-decoration: none!important;
}

.resort a.btn-reserve {
    background-color: #04416D;
}

.resort a.btn-hold {
    background-color: #d6dadd;
    color: #04416D!important;
}

.resort .checkbox label {
    font-size: 1.4rem;
}

.resort .radio label,
.resort .checkbox label {
    padding: 0;
    /* 	color:#a9a9a9; */
    color: #04416D;
}

.resort .checkbox {
    margin-bottom: 0;
}

.resort .checkbox .cr,
.resort .radio .cr {
    color: #a9a9a9;
    background-color: white;
    vertical-align: middle;
}

.resort div:not(div[data-role="response"]) hr {
    border-top: 2rem solid #f4f5f6;
    padding-bottom: 2rem;
    margin-top: 4rem;
}

.group-item {
    margin-bottom: 1rem;
}

.group-item strong {
    color: #04416D;
    display: block;
    padding: 0.5rem 0;
}

.group-item p {
    margin-top: 2rem;
    color: #707070;
    padding-right: 1.5rem;
    margin-bottom: 2rem;
}

.highlight {
    margin-top: 0rem!important;
    color: #529AD3!important;
}

a.share {
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

a.share.fb {
    background-image: url('images/f-logo.png');
    height: 2rem;
    width: 1.8rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
}

a.share.email {
    height: 2rem;
    width: 3rem;
    background-image: url('images/mail.png');
}

.modal a.share {
    -webkit-filter: grayscale(1) invert(1) contrast(500%);
    filter: grayscale(2) invert(1) contrast(500%);
}


/**/

span[aria-live="polite"] {
    display: block;
    background-color: white;
    position: absolute;
    color: #cc4b9b;
    width: 100%;
    width: calc(100% - 30px);
    padding: 10px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -webkit-background-clip: padding-box;
    border-radius: 0 0 5px 5px;
}

.ui-autocomplete.ui-front ul {
    border-radius: 0 0 5px 5px!important;
    width: 100%;
}

.ui-draggable .modal-wrapper {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5), 0 1px 20px 0 rgba(0, 0, 0, 0.5)!important;
}

.ui-draggable .modal-wrapper table thead {
    cursor: pointer;
}


/**/

.rooms-number {
    margin-top: 0.5rem;
    width: 3.5rem!important;
    border-radius: .2rem;
    border: 1px solid gray;
    margin-bottom: 1rem;
    padding: 0.2rem 0.5rem;
    background-size: 0.8rem;
    background-position: calc(100% - 0.2rem) 0.4rem;
    background-repeat: no-repeat;
    background-image: url("images/up-down-arrows.png");
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}


/***/

.change-avatar,
.avatar-thumb {
    height: 200px;
    width: 200px;
    background-position: center center;
    background-size: cover;
    border-radius: 100px;
    display: block;
    position: static;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    display: block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-bottom: 2rem;
}

.avatar-thumb {
    margin: 0!important;
    top: 0;
    vertical-align: text-top;
    padding: 0;
}

.change-avatar img,
.avatar-thumb img {
    display: inline;
    width: 100%;
    object-fit: cover;
    height: 200px;
    left: 0;
    right: 0;
    position: absolute;
}

.avatar-thumb img {
    height: inherit;
    margin: 0;
    bottom: 0;
}

.pick {
    display: block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 0 0 6px;
    font-weight: 300;
}

#avatar input[type=file] {
    display: none;
}

.fit-in {
    background-position: center center;
    background-size: cover;
}


/*
.fit-in:hover {
	    background-size: 150%;
	    transition: all 5s;
}
*/


/**/

.availability-list {
    width: 100%;
    background-color: #fafafc;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-bottom: 20px solid #eceeef;
}

.availability-list th {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    text-align: inherit;
}

.availability-list tr td {
    padding: 0.25rem;
}

.availability-list tr:last-child td {
    padding-bottom: 4rem;
}

.availability-list tr>td:first-child {
    padding-left: 2rem;
}

.availability-list tr>td:last-child {
    padding-right: 2rem;
}

.availability-list a.reserve {
    color: #04416D;
    text-decoration: underline;
}

.availability-list a.hold {
    color: #c3c8ce;
    text-decoration: underline;
}

.ui-state-focus {
    border: 0!important;
    background: inherit!important;
}

.ui-autocomplete {
    border-radius: 0!important;
}


/**/

big {
    font-size:1.2rem;
    bottom:2rem;
    position:absolute;
    text-align:center;
    color:#CCC;
}


/**/

#loadMap .modal-wrapper {
    padding: 1rem!important;
}

a[data-target="#loadMap"] {
    background-image: url('images/world-icon.png')!important;
    background-size: 2rem;
    padding-right: 2.5rem!important;
}

div[id^="loadModal"] {
    width: 100%;
}

div[id^="loadModal"] table.dates {
    margin-bottom: 5rem;
}

div[id^="loadModal"] table:not(.dates) {
    padding-bottom: 0;
    margin-bottom: 0;
}

div[id^="loadModal"] div.modal-dialog {
    max-width: 800px;
    width: 100%;
}

div[id^="loadModal"] table.hotel thead {
    background-color: #e1e2e6!important;
}

div[id^="loadModal"] table.hotel thead th {
    padding: 1rem 1rem;
    vertical-align: middle;
}

div[id^="loadModal"] table thead {
    background-color: #f2f3f4;
    border: none;
    font-size: 1.4rem;
}

div[id^="loadModal"] table tbody td,
div[id^="loadModal"] table thead th {
    color: #6d7984;
}

div[id^="loadModal"] table tbody td a {
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    text-decoration: underline;
    color: #04416D;
}

div[id^="loadModal"] table.hotel th:first-child {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.4rem;
    padding-left: 5rem;
}

div[id^="loadModal"] table.hotel thead th:last-child {
    padding: 2rem 5rem 2rem 2rem!important;
}

div[id^="loadModal"] table.hotel th:not(:first-child) {
    font-size: 1.2rem!important;
}

div[id^="loadModal"] table.hotel th:not(:first-child):before {
    content: "∙ ";
}

div[id^="loadModal"] table.hotel thead th {
    color: #6c7982;
    padding: 2rem 1rem;
}

div[id^="loadModal"] table.dates thead th {
    padding: 1rem 1rem 0px 1rem;
}

div[id^="loadModal"] table thead th {
    text-align: center;
    padding: 3rem 1rem 0 1rem;
    font-size: 1.4rem!important;
}

div[id^="loadModal"] table tbody tr:first-child td {
    padding-top: 1rem;
}

div[id^="loadModal"] table tbody td:first-child,
div[id^="loadModal"] table thead th:first-child {
    padding-left: 4rem;
}

div[id^="loadModal"] table tbody td:last-child,
div[id^="loadModal"] table thead th:last-child {
    padding-right: 4rem!important;
}

div[id^="loadModal"] table tbody td:last-child {
    padding-right: 4rem;
}

div[id^="loadModal"] table.dates td:first-child {
    width: 250px;
}

div[id^="loadModal"] .modal-wrapper,
div[id^="loadModal"] table {
    border-radius: 0 1rem 0 0;
}

div[id^="loadModal"] table.first th:last-child {
    padding: 0;
    border-radius: 0 1rem 0 0;
}

div[id^="loadModal"] table thead button.close {
    position: absolute;
    top: 0!important;
    right: 0!important;
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin: 0!important;
}

div[id^="loadModal"] table tbody td {
    padding: 0.5rem 1rem;
}

div[id^="loadModal"] table tr td {
    text-align: center;
}

div[id^="loadModal"] table tr td:first-child {
    width: 35%;
    text-align: center;
}

div[id^="loadModal"] .table>thead>tr>th,
div[id^="loadModal"] .table>tbody>tr>th,
div[id^="loadModal"] .table>tfoot>tr>th,
div[id^="loadModal"] .table>thead>tr>td,
div[id^="loadModal"] .table>tbody>tr>td,
div[id^="loadModal"] .table>tfoot>tr>td {
    border: none!important;
    font-size: 1.2rem;
}

div[id^="loadModal"] .modal-wrapper {
    padding: 0rem 1.5rem;
}

div[id^="loadModal"] td.price {
    font-weight: 600;
    color: #04416D;
}

div[id^="loadModal"] td.savings {
    font-weight: 600;
    color: #ea732d;
}


/**/

.inventory .modal-wrapper .btn-modal {
    background-color: #04416D;
}


/**/

.checkout .description .name {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.checkout .description .extra {
    font-weight: 600;
}

.checkout .description .address {
    margin: 1rem 0;
    font-size: 1.3rem;
    color: #6C7B86;
}

.checkout .description .rating {
    margin: 0 0 1rem 0;
    padding-left: 0;
}

.checkout .resort-info {
    padding: initial;
    margin: initial;
}

.checkout .resort-info ul {
    width: 100%;
    column-count: 2;
    font-size: 1.3rem;
    margin-top: 1.5rem;
}

.checkout .name {
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
}

.checkout .description ul {
    column-count: 1;
    color: #6C7B86;
}

.checkout .resort-info ul li {
    text-align: left;
    display: block;
    line-height: 1.5;
}

.checkout .resort-info ul li:before {
    content: '\00B7\00a0';
}

.checkout .description {
    padding-left: 3rem;
    padding-top: 1.5rem;
    padding-right: 0;
}

.checkout .price-detail {
    font-size: 1.7rem;
    padding: 0 1.5rem;
    line-height: 1.6;
}

.checkout .price-detail .discount {
    font-size: inherit;
    padding: 0.5rem 0;
}

.checkout .price-detail .final {
    color: #465765;
    font-weight: 600;
}

.checkout hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.checkout .description>div {
    margin-bottom: 1rem;
}

.checkout .resort-info .img-responsive {
    height: initial;
    max-height: initial;
    border: 1px solid #c9c9c9;
}

.checkout .wallet-options,
.details-box {
    margin: 0 0.5rem 0 0;
}

.checkout .cr {
    border-color: #ea732d;
}

.checkout .cr,
.checkout .cr-icon {
    color: #ea732d;
    font-size: 1.4rem;
    background-color: white;
}

.checkout .checkbox {
    font-size: 1.2rem;
}

.checkout .checkbox a {
    color: #E7732B;
    text-decoration: underline;
}

.checkout .item:not(:last-child) {
    margin-bottom: 1.5rem;
}

.remove {
    color: #88939c;
    font-size: 1.4rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 3;
}

.remove span {
    width: 1.8rem;
    height: 1.8rem;
    background-image: url('images/close-modal.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    display: inline-block;
    margin-left: 6px;
}


/****/

.item h2 {
    padding: 0;
    margin: 0;
}

.tab-payment img {
    display: inline;
}

.tab-payment .nav-tabs>li.active>a,
.tab-payment .nav-tabs>li.active>a:hover,
.tab-payment .nav-tabs>li.active>a:focus {
    border-color: transparent;
}

.tab-payment .nav-tabs {
    border-color: transparent;
    margin-bottom: 2rem;
}


/*\00B7*/

.tab-payment a[data-toggle=tab] {
    text-align: center;
}

.tab-payment a[data-toggle=tab] span {
    height: 2rem;
    width: 2rem;
    border: 1px solid gray;
    border-radius: 5rem;
    display: inline-block;
    vertical-align: text-top;
    line-height: 1.8rem;
    text-align: center;
    margin-right: 0.5rem;
}

.tab-payment .nav-tabs li:not(.active) span:before {
    content: '';
}


/**/

.checkout ::-webkit-input-placeholder,
.checkout ::-moz-placeholder {
    color: #ea732d!important;
}

.btn-continue,
.btn-cancel,
.btn-back,
.btn-default,
.btn-payment {
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    color: white;
    border-radius: 0.2rem;
    text-align: center;
    cursor: pointer!important;
}

.btn-payment {
    border: none!important;
}

.informative-total .btn-payment {
    margin-top: 2rem;
}

.btn-back {
    border: 1px solid #04416D!important;
    background-color: transparent!important;
    color: #04416D!important;
}

.btn-continue,
.btn-continue:hover,
.btn-continue:active,
.btn-continue:visited,
.btn-continue:link {
    color: white;
    background-color: #04416D!important;
}

.btn-cancel {
    background-color: #D3D6DA!important;
    color: #04416D;
}

.btn-default,
.btn-default:hover,
.btn-default:active,
.btn-default:visited,
.btn-default:link {
    background-color: #9ba4ab!important;
    color: white;
}

.btn-default{
  margin-top: 0rem !important;
}

.btn-payment,
.btn-payment:hover,
.btn-payment:active,
.btn-payment:visited,
.btn-payment:link {
    color: white;
    background-color: #04416D!important;
}


/**/

span.section-icon {
    width: 2.5rem;
    display: inline-block;
    height: 2.5rem;
    margin-right: .5rem;
    vertical-align: text-bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.section-icon.policy-restrictions {
    background-image: url('images/details/policy-restrictions.png');
}

.section-icon.urgent-info {
    background-image: url('images/details/urgent-info.png');
}

.section-icon.mandatory-fees {
    background-image: url('images/details/mandatory-fees.png');
}

.section-icon.facility-amenities {
    background-image: url('images/details/facility-amenities.png');
}

.section-icon.available-services {
    background-image: url('images/details/available-services.png');
}

.section-icon.services-facilities {
    background-image: url('images/details/services-facilities.png');
}

h3.refer {
    background-image: url('images/modals/head-refer.png');
}

h3.certificate {
    background-image: url('../../images/v2/wallet/certificates.png');
}

h3.cards {
    background-image: url('../../images/v2/wallet/vacation-cards.png');
    padding-left: 6.5rem;
    background-repeat: no-repeat;
}

h3.cash {
    padding-left: 6.5rem!important;
    background-image: url('../../images/v2/wallet/cash-card.png');
    background-repeat: no-repeat;
}

.gift-certificate span {
    background-image: url('images/wallet/gift-icon.png');
    background-repeat: no-repeat;
    width: 2.2rem;
    height: 2.2rem;
    vertical-align: middle;
    display: inline-block;
}

#details-cards .gift-certificate span {
    background-image: url('images/wallet/gift-card-icon.png')!important;
}

#details-cards .gift-certificate {
    color: #092f87!important;
}

#details-certs .gift-certificate {
    color: #529ad3!important;
}

.gift-certificate {
    background-image: none;
    font-family: 'Poppins', Verdana, sans-serif;
    font-weight: 600;
    text-decoration: none;
    display: block;
    width: 100%;
}


/****/

.history .details-box td a {
    text-align: left!important;
    text-transform: uppercase;
    font-weight: 600!important;
    padding: 0.5rem 1rem!important;
}

.history .details-box td:not(:last-child) strong {
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
}

.history .details-box td:last-child strong {
    display: block;
    padding: 0 1rem 0.5rem 1rem;
    text-align: left;
    color: #ea732d;
}

.history .details-box table.failed td {
    color: #ea732d!important;
}

.history .container-fluid {
    background-color: #f9fafa;
    margin: 0 5px 0 0;
    border: 1px solid #ddd;
    border-bottom: 0;
}

.history .nav-tabs.navbar-right {
    border: none!important;
}

.history .nav-tabs.navbar-right a {
    border-radius: 0!important;
    margin: 0 5px;
    font-size: 1.4rem;
    margin: 0;
    border-top: 0;
}

.history .nav-tabs.navbar-right li.active a {
    text-decoration: underline;
    font-weight: 600;
}

.history .nav-tabs.navbar-right a:hover {
    color: #04416D!important;
    cursor: pointer;
}

.history .details-box table.failed a {
    color: #04416D!important;
}

.history .details-box table.failed th:last-child {
    text-align: left!important;
}

.history #message .btn-continue {
    margin-top: 2rem;
}

.history #message img {
    margin-bottom: 3rem;
}

.history #message .modal-dialog {
    width: 625px;
}

.history .modal-wrapper {
    padding: 5rem 3rem!important;
}

.history [id^="details-"] table td.desc {
    padding: 1rem 1rem 1rem 2rem!important;
}

#edit-voucher h3,
#edit-voucher small {
    padding-left: 0;
    color: #707d87;
}

#edit-voucher .modal-wrapper {
    margin-bottom: 0px;
    padding: 4rem 4rem 5rem;
}

#edit-voucher .form-control {
    padding: 0.5rem;
}

.sec-message {
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: 2rem;
    color: #606d79;
    letter-spacing: 0.1rem;
    font-family: 'Poppins-Light', Verdana, sans-serif;
    font-size: 1.3rem;
}

.sec-message p:not(:last-child) {
    padding-bottom: 1.5rem;
}

#contact-info .bordered {
    padding: 2rem 2rem 2rem 2rem;
}

.head-box {
    margin: 0;
    padding: 3rem 2rem 1.5rem 2rem;
    color: #707d87;
    font-size: 18px;
}

.checkout .informative-total {
    margin-left: 1rem;
    margin-right: 0;
}

.checkout .informative-total .small-letters {
    font-size: 1.2rem;
    margin-top: 4rem;
}

.checkout .wallet-options {
    border-bottom: 0;
}

.checkout .details-box {
    margin: 0;
}

.tab-checkout {
    padding-right: 0;
}

.tab-content div:first-child .item {
    margin-right: 1rem;
}

.tab-checkout>ul.tabs {
    margin-bottom: 1rem;
    display: table;
    border: none;
    width: 100%;
}

.tab-checkout>ul.tabs li {
    border: 1px solid #c9c9c9;
}

.tab-checkout>ul.tabs li a {
    color: #a1a9b0;
    background-color: #fafafc;
    display: block;
    font-size: 14px;
    padding: 10px 15px;
}

.tab-checkout>ul.tabs li.active {
    border: 1px solid #ea732d;
}

.tab-checkout>ul.tabs li.active a {
    color: #ea732d;
    background-color: white;
}

.tab-checkout>ul.tabs li {
    padding: 0;
    font-weight: 600;
    text-align: center;
}

.tab-checkout>ul.tabs li a {
    border: none;
    width: 100%;
}

.tab-checkout>.tabs>li>a {
    border-radius: 0!important;
}

.absolute-total {
    font-size: 2.5rem;
    color: #04416D;
    font-weight: 600;
}

.price {
    color: #6b7883;
}

.discount,
.coupon {
    color: #ea732d;
    font-weight: 600;
    font-size: 1.4rem;
}

.back-link {
    font-size: 1.8rem;
    color: #A0A9B1;
    font-weight: 400;
    margin-bottom: 1rem;
    display: block;
}

.back-link:hover {
    color: inherit;
}

.checkout.purchase #wrapper .container {
    margin-top: 4rem;
    margin-bottom: 10rem;
}

.cards-options {
    margin: 0;
    padding: 0rem;
    border-top: 0;
    color: #87919a;
}

.cards-options .item .total,
.cards-options .item .total-price {
    font-size: 1.8rem;
    padding-bottom: 1.2rem;
    color: #04416D;
}

.cards-options .item .total-price {
    padding: 1.5rem 0 2rem 0;
}

.cards-options .item input.rooms-number {
    border-color: #63717d;
    color: #63717d;
}

.cards-options .item {
    padding: 3rem 0;
    margin: 0!important;
    border-right: 1px solid #dededf!important;
}

.cards-options .item img {
    border-radius: 5px;
    box-shadow: 1px 1px 5px #888888;
}

#hamburguer {
    /*float: right;
    position: absolute;
    right: 2%;*/
}

.detail-box .item {
    margin-left: 1rem;
    margin-right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.table {
    display: table;
    color: #04416D;
    text-align: center;
    font-size: 1.4rem;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.table-column {
    display: table-column;
}

.table-header-group {
    display: table-header-group;
}

.table .table {
    border: 1px solid #dbdee1;
    margin-bottom: 0;
}

.table-caption {
    display: table-caption;
    caption-side: bottom;
    border: 1px solid #dbdee1;
    border-top: none;
    border-bottom: 1rem solid #e8e8e8;
    padding-left: 2rem!important;
    padding-right: 2rem!important;
}

.table-row-group {
    display: table-row-group;
}

.table-cell,
.table-caption {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
}

.table.table-head {
    text-transform: uppercase;
    background-color: #c4c9cd;
    color: white;
    margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
    .head-belt {
        padding: 0;
        line-height: 1;
    }
    .absolute-total {
        padding: 1.5rem 0;
    }
    .checkout {
        border-top: 0;
    }
    .rooms-number {
        background-position: center right;
    }
    .checkout .details-box {
        margin: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
    .checkout .card-currency {
        padding-left: 40px;
        width: 100%;
        display: block;
    }
    .checkout .informative-total,
    .checkout.purchase .tab-content div:first-child .item {
        margin: 0!important;
        padding: 3rem 0!important;
    }
    .checkout .informative-total {
        padding-top: 0!important;
    }
    .checkout .cards-options {
        margin-bottom: 0;
    }
    .checkout .informative-total .price {
        padding: 2rem 0;
        border-bottom: .1rem solid #dededf;
        margin-bottom: 20px;
    }
    .tab-content div:first-child .item {
        margin-right: 0!important;
    }
    .price strong {
        color: #ea732d;
    }
    .price strong:before {
        content: '\00B7 ';
    }
    .cards-options .item .total-price {
        padding: 0 0 1.5rem 0;
    }
    .cards-options .item {
        border-bottom: .1rem solid #dededf;
    }
    /*
		.details-box{
			border-left: 0;
			border-right: 0;
			border-top:0;
		}
*/
    .navbar-header {
        max-width: 100%;
    }
}


/*@media(max-width: 1024px){
	.navbar-header img#hamburger {
		margin: 30px 0 30px 0%;
	}
	.navbar-header img:not(#hamburguer) {
		width: 220px;
		margin: 30px 0 30px 10%;

	}

	.navbar-right{ margin-right:  0px!important;}

	.navbar-header img.not(#hamburguer){
		width: 220px!important;
	}

	.nav>li {
    margin-right: 5px;
	}

}*/


/*@media(max-width: 769px){
	.navbar-header img:first-child{
		margin-right:-40px;
	}

	.navbar-toggle{
		margin: 0px;
		padding: 10px 10px 0px 10px;
	}

	footer {
		padding: 2rem 0;
	}

	.head-belt {
		padding:1rem;
	}
}*/

@media (max-width: 769px) {
    footer div.left,
    footer div.right {
        display: block;
        text-align: center!important;
    }
}


/*@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle, #header .nav{
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }

	.navbar-header {
		width: 100%;
	}

	#header a, #change-language{
		margin: 0px;
		text-align: center;
		border: none;
		width: 100%;
	}

	.nav.navbar-nav {
		margin:0px;
	}
	.nav>li, a#signin{
		padding:0px;
		margin:0px;
	}

	.nav>li a{
		padding: 8px 20px;
		text-align: left!important;
		font-size:18px;
		border-top: 1px solid white!important;
		text-transform: uppercase;
	}

	.nav>li:nth-child(1) a,
	.nav>li:nth-child(4) a{
		background-color: #a4ce3a;
	}

	.nav>li:nth-child(2) a,
	.nav>li:nth-child(5) a{
		background-color: #529ad3;
	}

	.nav>li:nth-child(3) a,
	.nav>li:nth-child(6) button{
		background-color: #ea732d;
	}

	.nav>li:last-child a{
		background-color:transparent;
	}

	#header > button {
		position: relative;
		right: 15px;
		top: 15px;
	}


}*/

/*.container>.navbar-header {
    padding: 0rem;
    /*position: relative;*
}
.navbar-header,
.navbar-title {
    position: absolute;
    top: 1.5rem;
    bottom: 1.5rem;
}
.navbar-title {
    left: 300px;
}
*	.booking .carousel-inner,
.resort .carousel-inner{
position:relative!important;
}
*/
}

@media(max-width: 992px) {
    /*.container {
		width: 100%;
		padding: auto 1.5rem;
	}

	.cards-options .item{
		border-left:0!important;
		border-right:0!important;
	}
	.item {
		padding: 1.5rem!important;
	}
	.hd{
		font-size: 2rem;
		color: gray;
		margin-top: 2rem;
		text-align: center;
	}
	.rating span, span.faces, span.stars{
		display:inline-block;
	}
	.rating small{
		margin-top: 0.5rem;
		display: block;
	}

	footer li {
		display:inline-block;
	}

	.navbar-header{
		max-width:100%;
	}

	.navbar-header img:not(#hamburguer){
	    /*max-height: 55px;
	    max-width: 225px;
	    margin: 0 3rem;
	    width: auto;*
	}

	.navbar-nav li>a {
		text-transform: capitalize;
	}

	.navbar-nav .open .dropdown-menu>li>a, .navbar-nav .open .dropdown-menu .dropdown-header{
		padding: 5px 15px 5px 60px;
	}

	.navbar-nav .dropdown.open ul.dropdown-menu {
		width: 100%!important;
	    display: block;
	    position: sticky;
	    margin-bottom: 10px;
	    box-shadow: none!important;
	}
	.navbar-nav>li:not(#itinerary){
		margin-left: 0rem;
	}

	.navbar-nav .dropdown-toggle a{
		background-image:url('/images/white_arrows_sprite.png');
	}
	.navbar-nav .dropdown-toggle[aria-expanded="true"] a{
		background-image:url('/images/white_arrows_sprite.png');
	}

	#header #wallet span.icon{
		margin:0;
		width: 3.5rem!important;
	}
	#header #currencies ul li a{
		background-color: #b2dd46!important;
	}
	#header #user ul li a{
		background-color: #67a6d9!important;
	}

	#search .form-control{
		margin:1rem 0;
	}

	.resort #wrapper #search hr {
    border-top: 1px solid #eee;
    margin-top: 2rem;
    margin-bottom: 0rem;
	}

	#search input[type=submit]{
		background-color: #ea732d;
	}

	.carousel-inner big {
		padding: 3rem 0 0rem;
	}

	.slider-buttons{
		height: 8rem;
		margin-top: 1rem;
	}
	.slider-buttons .carousel-control{
		text-shadow: none;
		opacity: 1;
		filter: alpha(opacity=100);
		margin-top: 1rem;
	}

	#wrapper .destinations {
	    margin-bottom: 0rem;
	    font-size: 2rem;
	    margin-top: 2.5rem;
	    border-bottom: 1px solid gray;
	}

	#wrapper .destinations h2, #wrapper #what-is h2{
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin: 0;
	}
	#wrapper #what-is {
		margin:0;
	}

	.navbar-nav .open .dropdown-menu>li>a{
		    line-height: normal;
	}
	footer ul{
		margin: 2rem 0;
	}
	footer ul li{
		display:block;
		width:100%;
		margin:0;
	}*/
    #nav-checkout {
        width: 100%;
        background-color: #6d7984;
        display: block;
        padding: 0.88rem;
        font-size: 1.8rem;
        background-image: url(images/right_arrow_white.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 2rem) center;
        color: white;
    }
    #wallet-expandable .dropdown-menu {
        width: 100%;
        margin-top: -1px;
        padding: 0;
        border-top: 2px solid white;
    }
    #wallet-expandable .wallet-options {
        border: 0;
        padding: 0;
        margin: -1px 0 0 0;
        background-color: #ea732d;
        font-size: 1.8rem;
    }
    #wallet-expandable .wallet-options input,
    #wallet-expandable .wallet-options select {
        font-size: 1.4rem;
    }
    #wallet-expandable .wallet-options li {
        display: block;
    }
    #wallet-expandable .wallet-options li:not(:last-child) {
        border-bottom: 1px solid #ef9761;
        box-shadow: none;
    }
    #wallet-expandable .wallet-options li:first-child {
        border-bottom: 1px solid #ef9761;
        box-shadow: none;
    }
    #wallet-expandable .wallet-options .checkbox label,
    #wallet-expandable .wallet-options .quantity,
    #wallet-expandable .wallet-options .cr-icon,
    #wallet-expandable .wallet-options .cr {
        color: white;
    }
    #wallet-expandable .wallet-options .cr-icon {
        color: #ea732d;
    }
    #wallet-expandable .wallet-options .checkbox {
        padding: 1.5rem 0;
    }
    #wallet-expandable .wallet-options .cr {
        border: 1px solid white;
        background-color: white;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.8rem;
    }
    #wallet-expandable .wallet-options select {
        border: 1px solid #fae6d5;
        background-color: transparent;
        margin: 0;
        padding: 0;
    }
    #wallet-expandable .title {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        font-family: 'Poppins-Light', Verdana, sans-serif;
    }
    #wallet-expandable .wallet-options #certs .quantity i,
    #wallet-expandable .wallet-options #certs .quantity_certs i {
        background-image: url('images/wallet/certs_white.png');
    }
    #wallet-expandable .wallet-options #cards .quantity i,
    #wallet-expandable .wallet-options #cards .quantity_certs i {
        background-image: url('images/wallet/cards_white.png');
    }
    #wallet-expandable .wallet-options #cash .quantity i,
    #wallet-expandable .wallet-options #cash .quantity_certs i {
        background-image: url('images/wallet/cash_white.png');
        height: 3rem;
        width: 3rem;
    }
    #wallet-expandable .wallet-options .quantity i,
    #wallet-expandable .wallet-options .quantity_certs i{
        background-size: 3rem;
        height: 3rem;
        background-size: contain;
    }
    #wallet-expandable .wallet-options input[name="cash_amount"] {
        background-color: transparent;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        width: initial;
    }
    #wallet-expandable .dropdown-toggle {
        background-color: #ea732d;
        width: 100%;
        display: block;
        min-height: 6rem;
        height: 100%;
        cursor: pointer;
        vertical-align: initial;
        background-repeat: no-repeat;
        background-position: calc(100% - 2rem) center, 2rem center;
        background-image: url(images/arrow_down_white.png), url(images/wallet-icon.png);
    }
    #wallet-expandable .dropdown-toggle[aria-expanded="false"] {
        background-image: url(images/arrow_down_white.png), url(images/wallet-icon.png);
    }
    #wallet-expandable .dropdown-toggle[aria-expanded="true"] {
        background-image: url(images/arrow_up_white.png), url(images/wallet-icon.png);
    }
    .checkout.purchase .collapse.in {
        width: 100%;
    }
    .checkout.purchase #wallet-expandable .wallet-options ul>li:first-child {
        background-color: #f3f3f3;
        padding: 1.5rem 0.5rem;
        font-size: medium;
        border: 1px solid #c9c9c9!important;
    }
    .checkout.purchase .wallet-options .quantity strong {
        color: white!important;
        border-color: white!important;
    }
    /*footer ul li.follow-us{
		margin:0;
	}

	#search-vertical  .details-box{
		padding: 2rem 1rem;
		margin: 0;
	}

	#search-vertical input.check-in{
		background-position: calc(1rem) center;
		padding-left: 4rem;
	}

	#search-vertical input[type="submit"],
	#search-map input[type="submit"]{
		background-color: #ea732d;
	}

	#search-vertical .search-title {
		font-size: 2.2rem!important;
		font-family: 'Poppins', Verdana, sans-serif!important;
		padding: 1rem!important;
/* 		background-color: #a4acb3!important; *
	}

	#search-vertical .checkbox .cr .cr-icon,
	#search-vertical .radio .cr .cr-icon{
		color:#ee772f;
	}

	#search-vertical .checkbox .cr,
	#search-vertical .radio .cr{
		background-color:white;
		width:2rem;
		height:2rem;
	}


	#search-vertical .select-collapse.collapsed{
		background-image: url('images/arrow_down_white.png')!important;
	}

	#search-vertical .select-collapse:not(.collapsed){
		background-image: url('images/arrow_up_white.png')!important;
	}

	#search-vertical a[data-toggle="collapse"]{
		    border: none!important;
    background-color: transparent!important;
    color: gray!important;
    padding-right: 3rem!important;
	}

	#search-vertical #search-title{
		margin: 0;
		text-overflow: ellipsis;
		background-position-x: calc(100% - 1.5rem);
		background-color: #a4acb3!important;
		background-image: url('images/magnifying_glass_white.png')!important;
	}

	#search-vertical #show-search.collapse.in{
		margin-top:2rem;
	}

	.tab-content {
		display:flex;
	}

	.tab-content div:first-child .item{ margin:0!important;padding:0!important; }
/*

	.tab-checkout {
		padding-right:1.5rem!important;
	}
*

	.checkout .resort-info,
	.search-result .resort-info,
	.resort .resort-info,
	.wallet .details-box
	{
		border:1px solid #c9c9c9!important;
		margin-bottom:1.5rem!important;
	}

	.checkout.purchase #wrapper .container {
		margin-top: 1rem;
	}

	.checkout .description {
		padding-left:2rem;
	}

	.checkout .resort-info h3 {
		padding-bottom: 0;
		margin-top:0;
		font-size:1.6rem!important;
	}

	.checkout .description>div {
		margin-bottom: 0;
	}

	.checkout .resort-info strong {
		font-size: 1.6rem;
		margin: 0;
		padding: 1rem 0;
		display: block;
	}

	.checkout .remove {
		position: relative!important;
	}

	.date-available {
		border-top: 1px solid #c9c9c9!important;
		padding-top: 1rem;
		margin-top: 1rem;
	}

	.left-arranged {
		padding-left: 5px;
		display: table-cell;
	}



	/*WALLET*

	.wallet #certs h3 {
		padding-bottom: 0;
	}
	.wallet #wrapper>.container{
		padding-top:0;
	}
	.wallet .details-box .description{
		margin-bottom: 2rem;
	}
	.wallet .details-box {
		min-height: inherit;
	}
	.profile div.subtitle, .wallet div.subtitle {
		padding: 0 1.5rem;
	}

	.wallet .details-box{
		margin-bottom:0!important;
	}

	.wallet .modal.in .modal-dialog{
		width: calc(100% - 4rem);
		margin: 2rem;
	}

	.wallet .modal .modal-wrapper {
		padding:0!important;
		color: #04416D;
	}

	.wallet .gift-certificate {
		padding: 1rem 0;
		display:block;
		width:100%;
		border-radius: 0.3rem;
	}


	#details-cards .gift-certificate {
		background-color: #c3c4e1;
	}

	#details-certs .gift-certificate {
		background-color: #d4e6f4;
	}



	div[id^="details-"] .modal-body{
		padding-top: 0;
	}

	div[id^="details-"] .nav-tabs{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
	}

	div[id^="details-"] .nav-tabs li:first-child{
		width: 60%;
	}

	div[id^="details-"] .nav-tabs li:last-child{
		width: 40%;
	}

	div[id^="details-"] .nav-tabs li a{
		border:none;
		padding: 2rem 1rem 2rem 5.5rem;
		background-position: 0.8rem center;
		background-repeat: no-repeat;
		border-radius: 0!important;
	}


	div[id^="details-"] .nav-tabs li.active a {
		background-color:#fff!important;
	}

	div[id^="details-"] .nav-tabs li a {
		background-color:#dbdbdb!important;
	}

	div[id^="details-"] .nav-tabs li:not(.active) a {
		color:#87919a!important;
	}

	#details-cards .nav-tabs li.active a {
		color: #092f87!important;
	}

	#details-cards .nav-tabs li.active:first-child a {
		background-image:url('images/wallet/cards_valid_active.png');
	}

	#details-cards .nav-tabs li:not(.active):first-child a {
		background-image:url('images/wallet/cards_valid_inactive.png');
	}

	#details-cards .nav-tabs li.active:last-child a {
		background-image:url('images/wallet/cards_used_active.png');
	}

	#details-cards .nav-tabs li:not(.active):last-child a {
		background-image:url('images/wallet/cards_used_inactive.png');
	}

	#details-certs .nav-tabs li.active a {
		color: #529ad3!important;
	}

	#details-certs .nav-tabs li.active:first-child a {
		background-image:url('images/wallet/certs_valid_active.png');
	}

	#details-certs .nav-tabs li:not(.active):first-child a {
		background-image:url('images/wallet/certs_valid_inactive.png');
	}

	#details-certs .nav-tabs li.active:last-child a {
		background-image:url('images/wallet/certs_used_active.png');
	}

	#details-certs .nav-tabs li:not(.active):last-child a {
		background-image:url('images/wallet/certs_used_inactive.png');
	}

	.wallet a.link-right {
		font-size: 1.3rem;
	}

	.wallet .paginator-mobile {
		margin-top:2rem;

	}

	.wallet .slider-buttons {
		margin-top:0;
	}

	.wallet .modal-wrapper h3 {
		margin-top:2rem;
	}*/
}

.nav.wl-tabs a {
    padding: 9px 8px;
}

.nav.wl-tabs a small {
    font-size: 1rem;
}

.all-inclusive {
    color: #E87439;
    margin-bottom: 0;
}

.all-inclusive span {
    width: 30px;
    vertical-align: middle;
    height: 20px;
    margin-right: 5px;
    display: inline-block;
    background-position: center center;
    background-image: url('images/all-inclusive-icon-blue.png');
}

.price-info .all-inclusive span {
    background-image: url('images/all-inclusive-icon-white.png')!important;
}

.orange {
    color: white;
    background-color: #E87439!important;
    padding: 10px 35px;
    border-radius: 20px;
    display: block;
}

h3.mandatory-fees {
    padding-left: 35px!important;
    background-image: url('images/all-inclusive-icon-orange.png')!important;
    background-repeat: no-repeat;
}

h3.mandatory-fees,
h3.mandatory-fees small {
    color: #E67441!important;
}

span.all-inclusive {
    color: white!important;
    background-color: #E87439!important;
    display: block;
    padding: 10px 15px;
}

.resort a.all-inclusive {
    font-size: 1.2rem!important;
    text-decoration: underline!important;
    padding: 1rem!important;
    display: block!important;
    border: 1px solid;
    border-color: #E87439!important;
    border-radius: 25px;
}

a[data-role=load-modal].all-inclusive {
    width: calc(90%);
    margin: 15px auto;
    max-width: 157px;
    box-shadow: 0px 2px 5px #888888;
    background-color: white;
}

a[data-role=load-modal].all-inclusive:hover {
    background-color: #E87439!important;
    color: white!important;
}

.resort a.all-inclusive:hover {
    background-color: transparent;
    color: inherit;
    display: block;
}

.companies-benefited {
    background-color: #e5e7e9;
    padding-top: 50px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

.companies-benefited h3 {
    color: #04416D;
    font-family: 'Poppins-SemiBold', Verdana, sans-serif;
    font-size: 35px;
}

.companies-benefited .company-logo {
    height: 150px;
}

.companies-benefited .company-logo img {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.heigth-logo-whyInspira {
    height: 80px;
}

#title-WhyInspira {
    font-size: .8em;
    font-weight: 600;
    font-family: 'Poppins-Light', Verdana, sans-serif;
}

#subtitle-WhyInspira {
    text-transform: none;
    margin: 10px 20px 0;
    text-align: center;
    font-weight: 600;
    font-family: 'Poppins-Light', Verdana, sans-serif;
    line-height: 2;
    font-size: 15px;
    font-style: normal;
}

.bg-light-blue-about {
    background-image: url('images/piscina-panoramica.png');
    background-position: center center;
    background-size: cover;
    background-color: #529AD3;
    color: white;
    width: 100%;
    display: block;
    padding: 50px 20px;
    font-size: 22px;
    min-height: 300px;
}

.bg-light-blue-about .carousel-control.right,
.bg-light-blue-about .carousel-control.left {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 45px;
}

.bg-light-blue-about .carousel-control.right {
    background-image: url('images/rigth-arrow-customer-slider.png');
}

.bg-light-blue-about .carousel-control.left {
    background-image: url('images/left-arrow-customer-slider.png');
}

#about-steps {
    margin: 30px 0 20px 0;
    font-size: 24px;
    padding: 0px!important;
    text-align: left;
    font-family: 'Poppins', Verdana, sans-serif;
}

#discount-code-box {
    color: #ea732d;
    font-weight: bold;
}

.loading-dots:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
    animation: ellipsis steps(4, end) 1200ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0;
}

@keyframes ellipsis {
    to {
        width: 1.25rem;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25rem;
    }
}

@media screen and (max-width: 600px) {
    .date-available {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .date-available .form-control {
        text-align: center;
        padding: 1rem 0.5rem;
        height: auto;
        text-align-last: center;
    }
    .modal.in .modal-dialog {
        padding: 0;
        margin: 0;
    }
    #bs-navbar.navbar-collapse {
        margin: 0!important;
    }
    .modal-wrapper .do-fb {
        padding-left: 38px;
        padding-right: 0;
    }
    #wrapper #search {
        /*margin-bottom:200px;*/
    }
}


/*Share icons*/

@media(max-width:789px) {
    .cards-options .total {
        font-size: 3rem;
        font-family: 'Poppins-SemiBold', Verdana, sans-serif;
        color: #626d77;
    }
    .companies-benefited .company-logo img {
        width: calc(100% - 2rem);
        max-height: 80px;
        max-width: max-content;
    }
}

#receivePromotions .modal-wrapper {
    padding: 10px!important;
}

#receivePromotions #promo-header {
    background-image: url('images/receive_promo.jpg');
    margin: 0 0 10px 0;
    min-height: 220px;
    display: list-item;
    background-position: bottom center;
    background-size: contain;
    background-color: #a089ce;
    background-repeat: no-repeat;
}

#receivePromotions .sec-message {
    font-weight: 600!important;
}

#receivePromotions .radio {
    margin: 15px;
}

#receivePromotions .radio label {
    display: block;
    width: 150px;
    margin: 0 auto 5px auto;
}

#receivePromotions #promo-header img {
    max-width: 100%;
    margin: 15px auto;
    display: block;
    max-height: 60px;
}

@media(max-width:789px) {
    #receivePromotions #promo-header img {
        background-size: 150%!important;
        max-width: 205px!important;
    }
    #receivePromotions #promo-header {
        min-height: 200px;
        background-size: cover;
        background-position: bottom;
    }
    .final-price.price-info span.all-inclusive {
        margin: 15px -15px!important;
    }
    a[data-role=load-modal].all-inclusive {
        max-width: calc(100% - 40px)!important;
    }
}

.form-register {
    margin-bottom: 0;
    padding: 0;
}

.form-register .checkbox label {
    margin: 0px;
    padding: 0px;
}

.form-register .checkbox span:not(.cr) {
    display: inline-block;
    width: calc(100% - 27px);
    font-size: 1.3rem;
}

.form-register .cr-icon {
    color: #ea732d!important;
}

.form-register a {
    font-weight: 800;
    text-decoration: underline;
}

.expiration {
    display: block;
    font-size: 1.1rem;
    margin: 0 auto;
    color: gray;
    border: .1rem solid #dededf;
    width: 100%;
    border-bottom: 0;
    padding: 1.2rem;
    text-align: center;
}

#terms-conditions h2,
#privacy-policies h2 {
    font-size: 24px;
    margin: 0;
    padding-bottom: 20px;
}

#terms-conditions #scroll-terms {
    overflow-y: scroll;
    height: 310px;
    margin-bottom: 30px;
    text-align: justify;
}

#terms-conditions .bordered,
#privacy-policies .bordered {
    padding: 40px;
}

#terms-conditions [class="text-justify"],
#privacy-policies [class="text-justify"] {
    overflow: scroll;
    height: 300px;
    padding-left: 0;
}

#terms-conditions ol {
    list-style: none;
    margin: 0;
}


/* Let's get this party started */

#terms-conditions ::-webkit-scrollbar,
#privacy-policy ::-webkit-scrollbar {
    width: 12px;
}


/* Track */

#terms-conditions ::-webkit-scrollbar-track,
#privacy-policy ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #d4d8db;
    background: #d4d8db;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}


/* Handle */

#terms-conditions ::-webkit-scrollbar-thumb,
#privacy-policy ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #465665;
    -webkit-box-shadow: inset 0 0 6px #465665;
}

#terms-conditions ::-webkit-scrollbar-thumb:window-inactive,
#privacy-policy ::-webkit-scrollbar-thumb:window-inactive {
    background: #465665;
}

.informative table.table-striped tbody {
    border: 1px solid #d4d8db;
}

.informative table.table-striped th {
    text-align: center;
    text-transform: uppercase;
}


/* Styles for Resort Reviews */

.review_profile_photo {
    padding: 0;
}

.review_renglon {
    /* border-bottom: 3px solid #eee; */
    padding-top: 30px;
    background-color: #fff;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.review_author_name {
    font-size: 2.5rem;
}

.review_stars {
    vertical-align: bottom;
    padding-left: 1rem;
}

.description {
    text-align: justify;
    /* padding-top: 3rem; */
}


/*SEARCH TYPE*/

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 20px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: -5;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 12px;
    top: 0;
    bottom: -5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: darkorange;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

i.form-control-feedback {
    font-size: 2.2rem;
    line-height: 4.5rem;
    left: 0 !important;
    padding: 0 2rem;
    color: gray;
}

.rounded_left {
    border-radius: 5px 0 0 5px !important;
}

.rounded_right {
    border-radius: 0 5px 5px 0 !important;
}

.hotel_services img {
    width: 14rem;
}

div#search.searchrow {
    padding: 0 !important;
    background-image: none !important;
    background-color: #CCC !important;
}

.footer_mtb:hover a {
    color:#2AABE4;
}





.topnav #myLinks {

  top: 0rem;
}


@media(max-width: 1200px) {

  .view-map .map-wls ul.mobile {

        display: grid;
        text-align: center;
  }

  .navbar-nav>li{
    line-height: 6rem !important;
  }
  #itinerary a{
    position: relative !important;
    top:0 !important;
    bottom:0 !important;
  }
  #itinerary a.itinerary{
    line-height: inherit;
    float: left;
    padding-left: 2rem;
  }
  #itinerary span.total{
    float: left;
    padding-left: 1rem;
  }
  #itinerary a.checkout{
    line-height: 4rem;
    float: left;
    clear: left;
    width: 100%;
    text-align: center;
    height: 4rem;
    background-color: #666;
    vertical-align: middle;
    margin-bottom: -.5rem;
  }
  .navbar-nav>li{
  }
  .container>.navbar-collapse{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media(min-width: 1201px) {
  .navbar-header{
    line-height: 11.5rem;
  }

  #itinerary a.checkout{
    position:absolute;
    bottom:1.3rem;
    line-height:1.5rem;
    left: 0rem;
  }
  #itinerary span.total{
    display:inline-block;
    width:12rem;
  }
  #itinerary a.itinerary{
    position:absolute;
    top:2.5rem;
    line-height:1rem;
  }
  .dropdown-menu>li>a{
    padding: 1rem !important;
    line-height: 3rem;
  }
  .dropdown-menu>li>a span{
    margin-left: 0 !important;
  }
}

@media(min-width: 1200px) {
  #header #languages span {
      margin-right:1rem;
  }

  footer ul li {
    /* display: inline-block; */
  }
}

footer ul {
  /* display: inline-block; */
}

.topnav #myLinks {

  top: 0rem;
}

@media(min-width: 768px) {
  .navbar-header a>img {
    height: 7rem !important;
  }
}


/*DEDUCT FROM PRICE SECTION*/
th.deduct_from_price_text{
  vertical-align: middle;
  color: #E9732D;
  font-weight: bold;
  border-radius: 5px;
}

input.price-discount{
  height: 3rem;
  width: 9rem;
  text-align: center;
  background: white;
  line-height: 3rem;
}

div.total-available{
  height: 2rem;
  width: 11.5rem;
}

th.total-available{
  padding-left: 3rem;
  color: white;
}

tr.total-available{
  line-height: 4rem;
}

div.body-discount{
  height: 6rem;
  background-color: #E9732D;
}

div.header-discount{
  line-height: 3rem;
  color: white;
  height: 3rem;
  background-color: #E56123;
  text-align: center;
}

div.discount-section{
   border-radius: 1rem;
   width: 30rem;
   height: 9.5rem;
   border: solid #E56123;
}
