body {
	position:absolute;
	height:100%;
	width:100%;
}

#captcha {
	color: white;
	width: 100%;
	height: 100%;
	display: block;
	padding: 10% 20px;
	background-image: url(images/family-background-captcha.png);
	background-size: cover;
	background-position: center;
}

.border-clear {
	background-color:rgba(255, 255, 255, 0.2);
	padding: 25px;
	margin-top: 20px;
}
.gray-box {
	padding: 25px;
	color: #465664;
	background-color: #e5e7e9;
}

.gray-box img {
	width: 80%;
	margin: 20px 10%;
	border: 2px solid #bac0c6;
}
#captcha h2 {
	margin:0px 0px 10px;
}
#captcha img {
	width: 80%;
	max-width: 350px;
	margin: auto 10%;
}
a#refresh {
	color:#cc4b9b;
	margin-top:5px;
	margin-bottom: 15px;
	text-decoration: underline;
	display:block;
	background-image: url(images/pink-right-arrow.png);
	background-repeat: no-repeat;
	background-position: 88% calc(50% - 1px);
	background-size: 10px;
	width:auto;
}
#captcha input[type="text"]{
	border: none;
	background-color: #bac0c6;
	border-radius: 0px;
	padding: 5px;
	color: #465664;
}

#captcha input[type="submit"], 
#captcha div[data-role="submit"]{
	background-color: #465664;
	color: white;
	padding: 10px;
	margin-top:20px;
	background-image: url(images/right-arrow-lan.png);
    background-repeat: no-repeat;
    background-position: 90% center;
    border:none;
    width:100%;
}

